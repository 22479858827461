(function($){
    var $fn = $(".comp_returned");

    if($fn.length) {

        $(document).on('input', '[data-package-search]', function () {
            var elm = $(this);
            naja.makeRequest('GET', elm.data('package-search'), {search: elm.val()});
        });

        $(document).on('click', '[data-packages-sort-by]', function () {
            var elm = $('[data-package-search]');
            var sortType = $(this).attr('data-packages-sort-by');
            naja.makeRequest('GET', elm.data('package-search'), {search: elm.val(), sortType: sortType});
        });

        $.getScript(cdnjs.countup).done(function () {
            $fn.find(".wrp_comp_head").find(".wrp_values").find(".elm_value").each(function () {
                let el = $(this);

                let options = {
                    separator: ' ',
                    decimal: ',',
                    suffix: " " + el.data("suffix")
                };

                let counter = new CountUp(el.get(0),0,parseFloat(el.data("value")),1,3,options);
                if (!counter.error) {
                    counter.start();
                } else {
                    console.error(counter.error);
                }
            })
        });
        $(document).on("click","[data-send-payment-info]",function (e) {
            var packageId = $(this).attr('data-package-id');
            var returnedPackageAddressId = $('#returnedPackageAddressId').val();

            naja.makeRequest('GET', '', {do: 'sendPaymentInfo', packageId: packageId, addressId: returnedPackageAddressId}).then(function(data) {
                var message = data.message;
                if(typeof nl_lib_notification !== 'undefined') {
                    var $notification = $('[data-notification]');
                    if ($notification.length > 0) {
                        nl_lib_notification.open({
                            state: $notification.data('state'),
                            title: $notification.data('title'),
                            text: message
                        });
                    }
                }
            });

            /*$.ajax({
                data: {do: 'sendPaymentInfo', packageId: packageId}
            }).done(function (data) {
                var message = data.message;
                if(typeof nl_lib_notification !== 'undefined') {
                    var $notification = $('[data-notification]');
                    if ($notification.length > 0) {
                        nl_lib_notification.open({
                            state: $notification.data('state'),
                            title: $notification.data('title'),
                            text: message
                        });
                    }
                }
            });*/
        });

        $(document).on('click','[data-package-action="payment"]',function(e) {
            e.preventDefault();

            let selectedPayment = $('select#returnedPackagePayment').val();
            let ajaxUrl = $(this).attr('data-dialog-href');
            var $el = $(this);

            if($el.hasClass('mod--active')) {
                return;
            }

            $el.addClass("mod--active");

            /*$.ajax({
                url: ajaxUrl,
                method: 'post',
                data: {
                    selectedPayment: selectedPayment
                }
            }).done(function (data) {
                nl_lib_dialog.open(data.dialog, function() {

                });
            });*/

            naja.makeRequest('POST', ajaxUrl, {
                selectedPayment: selectedPayment
            });

        });

        $(document).on('click','[data-package-action="address"]',function(e) {
            /*e.preventDefault();*/


            let elem = $(this);
            elem.attr('disabled','disabled');

            /*setTimeout(function() {
                elem.removeAttr('disabled');
            }, 10000);*/

            if(typeof body !== undefined) {
                body.addClass("is--loading");
            }

            return;

            let selectedPayment = $('#returnedPackageSelectedPayment').val();
            let selectedAddress = $('#returnedPackageAddressId').val();
            let packageId = $('#returnedPackageId').val();

            if(selectedAddress.toString().length === 0) {
                $('#returnedPackageAddressId').parent().addClass('mod--invalid');
                return;
            }

            let ajaxUrl = $(this).attr('data-dialog-href');
            var $el = $(this);

            if($el.hasClass('mod--active')) {
                return;
            }

            $el.addClass("mod--active");

            /*naja.makeRequest('POST', ajaxUrl, {
                selectedPayment: selectedPayment,
                selectedAddress: selectedAddress,
                packageId: packageId
            });*/

        });

    }
})(jQuery);
