var selectBalikNaPostu;

//balík na poštu cz/sk

(function($){

    var $fn = $(".elm_select_branches_balik_na_postu");

    function SelectBalikNaPostuClass()
    {

        var _this = this;
        this.countryCode = null;
        this.selectElem = null;

        this.init = function()
        {

            this.selectElem = $('#balik_na_postu');
            this.countryCode = $('.elm_select_branches_balik_na_postu').attr('data-country');

            function initTippyBalikNaPostu()
            {
                const template = document.querySelector('#balikNaPostuOpenHours');
                var initialText;
                if(template !== null && template) {
                    initialText = template.textContent;
                } else {
                    initialText = '...';
                }

                var tip = tippy('.openingHoursBalikNaPostu', {
                    animation: 'shift-toward',
                    arrow: true,
                    html: '#balikNaPostuOpenHours',
                    onShow(instance) {
                        // `this` inside callbacks refers to the popper element
                        var content = this.querySelector('.tippy-content');

                        var customUrl = $(instance.reference).attr('data-url');

                        if (tip.loading || content.innerHTML !== initialText) return;

                        tip.loading = true;

                        $.ajax({
                            url: customUrl,
                            dataType: 'html'
                        }).done(function(data) {
                            content.innerHTML = data;
                            tip.loading = false
                        });

                    },
                    onHidden() {
                        const content = this.querySelector('.tippy-content');
                        content.innerHTML = initialText
                    },
                    // prevent tooltip from displaying over button
                    popperOptions: {
                        modifiers: {
                            preventOverflow: {
                                enabled: false
                            },
                            hide: {
                                enabled: false
                            }
                        }
                    }
                });
            }

            $(window).on('load',function() {
                initTippyBalikNaPostu();
            });

            function selectTemplateBalikNaPostu(data, container) {

                if(data.element && data.element.dataset && data.element.dataset.id) {
                    var branchId = data.element.dataset.id;
                    var city = data.element.dataset.city;
                    var city_part = data.element.dataset.city_part;
                    var address = data.element.dataset.address;
                    var zip = data.element.dataset.zip;
                    var id_db = data.element.dataset.id_db;
                } else {
                    var branchId = data.id;
                    var city = data.city;
                    var city_part = data.city_part;
                    var address = data.address;
                    var zip = data.zip;
                    var id_db = data.id_db;
                }

                if(city == city_part) {
                    city_part = '';
                } else {
                    city_part = ' ' + city_part;
                }

                if (!branchId) {
                    return "";
                } else {
                    return $(
                        "<div class='balikovna-address-content'><div class='cityPart'>" + city + ",</div>" +
                        "<div class='address'>&nbsp;" + address + city_part +  ",</div>" +
                        "<div class='kind'>&nbsp;" + zip + "</div></div>" +
                        "<div class='openingHoursBalikNaPostu tooltipTrigger icon icon--clock' data-url='?do=balikNaPostuGetOpenins&bId="+ id_db +"&countryCode=" + _this.countryCode + "'>" + "" + "</div>"
                    );
                }
            }

            var searchBalikNaPostu;

            var balikNaPostuSelect = _this.selectElem;
            var defaultValueId = balikNaPostuSelect.attr('data-default-id');

            balikNaPostuSelect.select2({
                language: "cs",
                ajax: {
                    url: "?do=searchBalikNaPostu&countryCode=" + _this.countryCode,
                    data: function (params) {
                        searchBalikNaPostu = params.term;
                        return {
                            q: params.term,
                        };
                    },
                    processResults: function (data) {
                        setTimeout(function () {
                            initTippyBalikNaPostu();
                        }, 300);
                        return {
                            results: $.map(data.branches, function (item) {
                                return {
                                    id: item.id,
                                    text: item.name,
                                    city: item.city,
                                    city_part: item.city_part,
                                    address: item.address,
                                    zip: item.zip,
                                    id_db: item.id_db
                                }
                            })
                        };
                    },
                    complete: function () {
                        if (searchBalikNaPostu !== undefined) {
                            setTimeout(function () {
                                initTippyBalikNaPostu();
                            }, 100);
                        }
                    }
                },
                minimumInputLength: 1,
                templateResult: selectTemplateBalikNaPostu,
                templateSelection: selectTemplateBalikNaPostu
            });

            if(defaultValueId) {

                $.ajax({
                    url: "?do=searchBalikNaPostu&countryCode=" + _this.countryCode + "&defaultId=" + defaultValueId,
                    dataType: 'json'
                }).done(function(data) {
                    if(data.branches && data.branches.length === 1) {

                        var branchData = data.branches[0];

                        var option = new Option(branchData.address, branchData.id, true, true);

                        for (let objKey in branchData) {
                            option.dataset[objKey] = branchData[objKey];
                        }

                        balikNaPostuSelect.append(option).trigger('change');

                        balikNaPostuSelect.trigger({
                            type: 'select2:select',
                            params: {
                                data: branchData
                            }
                        });
                    }
                });

            }

            balikNaPostuSelect.on('select2:select',function() {
                setTimeout(function () {
                    initTippyBalikNaPostu();
                }, 100);
            });


        };

    }

    selectBalikNaPostu = new SelectBalikNaPostuClass();

    if ($fn.length) {
        selectBalikNaPostu.init();
    }

})(jQuery);
