(function($){
    var $fn = $(".comp_articles");

    if($fn.length){
        $fn.on("click","[data-expand-toggle]",function (e) {

            if($(e.target).is('a') && !$(e.target).is('[data-expand-toggle]')) {
                return;
            }

            e.preventDefault();
            var el = $(this);
            var article = el.closest(".part_item_article");
            var content = article.find(".part_ui_wsw");
            var text = content.children().not(":first");
            var toggleButton = article.find('[data-expand-toggle-button]');

            if(content.is("[aria-expanded=\"true\"]")){
                text.slideUp(400,"linear");
                content.removeAttr("aria-expanded");
                toggleButton.find("i").text(toggleButton.data("more"));
                toggleButton.removeClass("mod--toggled");

            }
            else {
                text.slideDown(400,"linear");
                content.attr("aria-expanded","true");
                toggleButton.find("i").text(toggleButton.data("less"));
                toggleButton.addClass("mod--toggled");
            }
        })
    }
})(jQuery);