(function($){
    var $fn = $(".comp_gwtv_categories");

    if($fn.length){
        let slider = $fn.find("[data-slider]");

        if(slider) {
            $.getScript(cdnjs.flickity).done(function () {
                slider.flickity({
                    cellAlign: 'left',
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false,
                    groupCells: true,
                    on : {
                        dragStart: function () {
                            [...slider.data("flickity").slider.childNodes].forEach(slide=>{
                                slide.style.pointerEvents = "none";
                            })
                        },

                        dragEnd: function () {
                            [...slider.data("flickity").slider.childNodes].forEach(slide=>{
                                slide.style.pointerEvents = null;
                            })
                        }
                    }
                });

                $fn.on("click","[data-slider-prev]",function (e) {
                    e.preventDefault();
                    slider.flickity("previous");
                });

                $fn.on("click","[data-slider-next]",function (e) {
                    e.preventDefault();
                    slider.flickity("next");
                });
            });
        }
    }
})(jQuery);