(function ($) {
    var $fn = $(".comp_gallery_album");

    if ($fn.length) {
        $fn.on("mouseenter",".part_item_gallery_video",function () {
            var $img = $(this).find("img");
            if ($img.attr("data-video")!==""){
                $img.prop("src",$img.data("video"));
            }
        });

        $fn.on("mouseleave",".part_item_gallery_video",function () {
            var $img = $(this).find("img");
            if ($img.attr("data-video")!==""){
                $img.prop("src",$img.data("src"));
            }
        })
    }
})(jQuery);