(function ($) {
    var fn = ".part_ui_number";
    var $val;

    $(document).on("click", fn + " [data-number]", function (e) {
        e.preventDefault();

        if(typeof otherProductsChangePlus !== 'undefined') {
            if($(this).attr('data-number') === 'plus') {
                otherProductsChangePlus = true;
            } else {
                otherProductsChangePlus = false;
            }
        }


        var $inp = $(this).closest(fn).find("input"),
                $num;
        if ($(this).is('[data-number="plus"]')) {
            $num = +$inp.val() + $inp.data('step');
            if ($num <= $inp.attr('max')) {
                $inp.val($num).change();
            }
        } else if ($(this).is('[data-number="minus"]')) {
            $num = +$inp.val() - $inp.data('step');
            if ($num >= $inp.attr('min')) {
                $inp.val($num).change();
            }
        }
    });

    $(document).on("click", fn + " input", function () {
        if ($(this).attr("readonly")) {
            return;
        }
        $val = $(this).val();
        $(this).val("");
        $(this).blur(function () {
            var val_change = $(this).val();
            if (val_change === "") {
                $(this).val($val);
            }
        });
    });

    $(document).on("change", fn + " input", function () {
        $val = parseInt($(this).val());
        if ($val % $(this).data('step') !== 0) {

            $(this).val($val + $(this).data('step') - $val % $(this).data('step')).change();
        }
    });

    $(document).on("propertychange input", fn + " input", function (e) {
        var valueChanged = false;

        if (e.type == 'propertychange') {
            valueChanged = e.originalEvent.propertyName == 'value';
        } else {
            valueChanged = true;
        }
        if (valueChanged) {
            var $inp = $(this);
            if ($inp.val().toString().length > $inp.attr('max').length) {

                $inp.val(parseInt($inp.attr('max'))).change();
            }
        }
    });
})(jQuery);