//____ PARALLAX, NOTES: apply on image wrapper ____ //
//____ AUTHOR: materializecss.com ____ //
$.fn.parallax = function () {
    var window_width = $(window).width();

    return this.each(function(i) {
        var $this = $(this);
        function updateParallax(initial, initial_img_height) {
            var container_height;
            if (window_width < 960) {
                return false;
            } else {
                container_height = ($this.height() > 0) ? $this.height() : 500;
            }
            var $img = $this.children("img").first(),
                img_height = $img.height(),
                parallax_dist = img_height - container_height,
                bottom = $this.offset().top + container_height,
                top = $this.offset().top,
                scrollTop = $(window).scrollTop(),
                windowHeight = window.innerHeight,
                windowBottom = scrollTop + windowHeight,
                percentScrolled = (windowBottom - top) / (container_height + windowHeight),
                parallax = Math.round((parallax_dist * percentScrolled));
            if (initial) {
                img_height = initial_img_height,
                parallax_dist = img_height - container_height,
                parallax = Math.round((parallax_dist * percentScrolled));
            }
            if ((bottom > scrollTop) && (top < (scrollTop + windowHeight))) {
                $img.css('transform', "translate3D(0," + parallax + "px, 0)");
            }

        }
        $this.children("img").beforeImgLoad(function(width, height) {
            updateParallax(true,height);
            $this.children("img").css("opacity","1");
        });

        $(window).scroll(function() {
            window_width = $(window).width();
            updateParallax(false,false);
        });

        $(window).resize(function() {
            window_width = $(window).width();
            $this.children("img").css("opacity","1");
            updateParallax(false,false);
        });
    });
};
