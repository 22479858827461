(function ($) {
    var $fn = $(".comp_email_edit");

    if ($fn.length) {
        tinymce.init({
            branding: false,
            selector: '.tinymceselector',
            height: 450,
            menubar: false,
            plugins: "paste code lists",
            toolbar: 'undo redo | bold italic underline | bullist numlist | code',
            entity_encoding: "raw"
        });

        $('[data-tinymce-add]').on('click', function () {
            var t = $(this).data('tinymce-add');
            tinymce.execCommand('mceInsertContent', false, t);
        });
    }
})(jQuery);