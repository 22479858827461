(function ($) {
    var $fn = $(".comp_gallery_single");

    if ($fn.length) {
        var $grid = $fn.find(".wrp_comp__body");

        $grid.lightGallery({
            thumbnail: true,
            selector: ".elm_galley_item",
            exThumbImage: 'data-exthumbimage',
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: false,
            download: true,
            autoplay: false,
            autoplayControls: false,
            share: false
        });
    }
})(jQuery);