(function($){
    let $fn = $(".comp_gwtv_category");

    if($fn.length){
        let navTop = $fn.find("[data-ref-nav-top]");
        let navBottom = $fn.find("[data-ref-nav-bottom]");

        if(navTop.length) {
            navTop[0].scrollLeft = navTop.find(".mod--active").closest(".col")[0].offsetLeft - navTop[0].offsetLeft;

            navTop.on("scroll",function () {
                if(navTop[0].scrollLeft === navTop[0].scrollWidth - navTop[0].clientWidth) {
                    navTop.closest(".elm_nav").addClass("mod--end");
                }
                else {
                    navTop.closest(".elm_nav").removeClass("mod--end");
                }
            });
        }

        if(navBottom.length) {

            if(typeof navBottom.find(".mod--active").closest(".col")[0] !== 'undefined') {
                navBottom[0].scrollLeft = navBottom.find(".mod--active").closest(".col")[0].offsetLeft - navBottom[0].offsetLeft;
            }

            navBottom.on("scroll",function () {
                if(navBottom[0].scrollLeft === navBottom[0].scrollWidth - navBottom[0].clientWidth) {
                    navBottom.closest(".elm_nav").addClass("mod--end");
                }
                else {
                    navBottom.closest(".elm_nav").removeClass("mod--end");
                }
            });
        }
    }
})(jQuery);