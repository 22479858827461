let cdnjs = {
    "picturefill": "https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js",
    "eac": "https://cdnjs.cloudflare.com/ajax/libs/easy-autocomplete/1.3.5/jquery.easy-autocomplete.min.js",
    "sortable": "https://cdnjs.cloudflare.com/ajax/libs/Sortable/1.6.0/Sortable.min.js",
    "inputmask": "https://fastly.jsdelivr.net/npm/inputmask@4.0.5/dist/min/jquery.inputmask.bundle.min.js",
    "countup": "https://fastly.jsdelivr.net/npm/countup.js@1.9.3/dist/countUp.min.js",
    "datepicker": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/datepicker.min.js",
    "datepicker_lang": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker.cs.min.js",
    "progressbarjs": "https://fastly.jsdelivr.net/npm/progressbar.js@1.0.1/dist/progressbar.min.js",
    "slimselect": "https://fastly.jsdelivr.net/npm/slim-select@1.23.1/dist/slimselect.min.js",
    "slimselect_css": "https://fastly.jsdelivr.net/npm/slim-select@1.23.1/dist/slimselect.min.css",
    "flickity": "https://fastly.jsdelivr.net/npm/flickity@2.2.2/dist/flickity.pkgd.min.js"
};
