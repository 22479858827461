(function($) {
    var $timeout,
        $fn = $(".part_ui_option");

    $(document).on("click",".part_ui_option [aria-expanded]",function(e){
        var $el = $(this);
        e.preventDefault();
        e.stopPropagation();
        clearTimeout($timeout);

        $.fn.close = function(off) {
            let $this = $(this);
            $this.removeClass("mod--toggled").next("[aria-hidden]").attr("aria-hidden","true");
            $timeout = setTimeout(function(){
                $this.attr("aria-expanded","false");
                if (!off) {
                    html.off("click");
                }
            },300);
        };
        $.fn.open = function() {
            $(this).addClass("mod--toggled").attr("aria-expanded","true").next("[aria-hidden]").removeAttr("aria-hidden");
        };

        $("html").on("click", function(e) {
            if(!$(e.target).closest(".part_ui_option").length) {
                $el.close();
            }
        });
        if ($el.hasClass("mod--toggled")) {
            $el.close();
        } else {
            $(".part_ui_option").find("[aria-expanded]").not(this).close(true);
            $el.open();
        }
    });
})(jQuery);