var initSelectValidation;
(function($) {
    $(".part_ui_select").find("select").on("click",function() {
        $(this).parent().toggleClass("mod--focus");
        $(this).one("blur",function() {
            $(this).parent().removeClass("mod--focus");
        });
    });

    $.fn.selectValidation = function (opts) {

        $(this).removeClass("mod--invalid mod--valid");

        let select = $(this).find('select');
        let selectVal = $(this).find('select').val() || '';
        let emptyVal = selectVal.toString().length <= 0;

        if (!emptyVal || (opts && typeof opts.initial !== 'undefined' && opts.initial === true)) {

            if (select.is(":valid")) {
                $(this).addClass("mod--valid");
            } else {
                $(this).addClass("mod--invalid");
            }
        }

    };

    $(document).on("change", ".part_ui_select.mod--required", function () {
        if (!$(this).filter("[data-no-validation]").length) {
            $(this).selectValidation();
        }
    });

    initSelectValidation = function()
    {
        $(".part_ui_select.mod--required[data-init-validation]").each(function () {
            if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid")) {
                $(this).selectValidation({
                    initial: true
                });
            }
        });
    };
    initSelectValidation();

    const multiSelects = $(".part_ui_select").find("select[multiple]")

    if(multiSelects.length > 0) {
        $.getStyle(cdnjs.slimselect_css);
        $.getScript(cdnjs.slimselect).done(function () {
            multiSelects.each(function () {
                const select = $(this);
                const parent = select.closest('.part_ui_select');

                parent.addClass('mod--multiple');

                const slim = new SlimSelect({
                    select: select[0],
                    showSearch: false,
                    searchPlaceholder: 'Hledat',
                    searchText: 'Nic nenalezeno',
                    searchingText: 'Probíhá hledání...',
                    allowDeselectOption: true,
                    beforeOpen: () => {
                        parent[0].style.zIndex = "10";
                    },
                    afterClose: () => {
                        parent[0].style.zIndex = null;
                    }
                });
            })
        })
    }

    $.getStyle(cdnjs.slimselect_css);
    $.getScript(cdnjs.slimselect).done(function () {
        $('[data-slim-select]').each(function () {
            const select = $(this);
            const parent = select.closest('.part_ui_select');

            parent.addClass('mod--multiple');

            new SlimSelect({
                select: select[0],
                showSearch: true,
                searchPlaceholder: 'Hledat',
                searchText: 'Nic nenalezeno',
                searchingText: 'Probíhá hledání...',
                allowDeselectOption: true,
                beforeOpen: () => {
                    parent[0].style.zIndex = "10";
                },
                afterClose: () => {
                    parent[0].style.zIndex = null;
                }
            });
        })
    })
})(jQuery);
