(function($){
    var $fn = $(".comp_product_detail");

    if($fn.length){
        $fn.on("click","[data-gallery]",function () {
            var $this = $(this),
                $gallery_data = $this.data("gallery"),
                $gallery_index = ($this.data("index"))?$this.data("index"):0;

            $this.lightGallery({
                dynamic: true,
                thumbnails: true,
                counter: true,
                download: true,
                closable: true,
                zoom: false,
                dynamicEl: $gallery_data,
                index: $gallery_index
            });

            $this.one("onCloseAfter.lg", function() {
                $this.data('lightGallery').destroy(true);
            });
        });

        $fn.on("click","[data-big]",function () {
            var $this = $(this),
                $big = $this.data("big"),
                $index = $this.closest(".elm_image").data("index"),
                $target = $fn.find("[data-gallery]");

            if(!$this.closest(".elm_image").hasClass("mod--active")){
                $fn.find(".wrp_thumbnails .mod--active").removeClass("mod--active");
                $this.closest(".elm_image").addClass("mod--active");
                $target.addClass("mod--loading");
                $fn.find(".wrp_thumbnails").addClass()
                setTimeout(function () {
                    $target.attr("data-index",$index).data("index",$index).find("img").attr("src",$big);
                    setTimeout(function () {
                        $target.removeClass("mod--loading")
                    },150);
                },250);
            }
        });

        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/numeral.js/2.0.6/numeral.min.js").done(function () {
            var $input = $fn.find(".part_ui_number input"),
                $unit_price = $fn.find("[data-unit-price]").data("unit-price"),
                $price_elm = $fn.find("[data-price]");

            numeral.localeData().delimiters.thousands = ' ';

            if($('html').data('currency') == 'eur') {
                numeral.defaultFormat('0,0.00');
            } else if($('html').data('currency') == 'czk') {
                numeral.defaultFormat('0,0');
            } else if($('html').data('currency') == 'pln') {
                numeral.defaultFormat('0,0.00');
            }

            $price_elm.text(numeral($input.val()*$unit_price).format());

            $input.on("change",function () {
                var $count = $(this).val();
                var $default_unit_price = $fn.find("[data-unit-price]").data("unit-price");
                var $unit_price = $default_unit_price;

                if ($count >= 5 && $('[data-unit-price3]').length) {
                    var $unit_price_discounted = $fn.find("[data-unit-price3]").data("unit-price3");
                    var $spare_pieces = $count % 5;
                    var $total_price = ($count - $spare_pieces) * $unit_price_discounted + $spare_pieces * $default_unit_price;

                    $unit_price = $total_price / $count;
                }

                if ($count >= 10 && $('[data-unit-price2]').length) {
                    var $unit_price_discounted = $fn.find("[data-unit-price2]").data("unit-price2");
                    var $spare_pieces = $count % 10;
                    var $total_price = ($count - $spare_pieces) * $unit_price_discounted + $spare_pieces * $default_unit_price;

                    $unit_price = $total_price / $count;
                }

                $price_elm.text(numeral($count*$unit_price).format());
            })
        });

        const description = $fn.find('[data-ref="description"]')
        const descriptionToggle = $fn.find('[data-ref="descriptionToggle"]')

        cssLoaded(function () {
            if(description[0].scrollHeight > description[0].clientHeight) {
                descriptionToggle.html($fn.find('[data-ref="descriptionExpand"]').html())
            }
        })

        $fn.on("click",'[data-click="descriptionExpand"]', function (e) {
            e.preventDefault()
            description[0].style.maxHeight = `${description[0].scrollHeight}px`

            descriptionToggle.html($fn.find('[data-ref="descriptionCollapse"]').html())
        })

        $fn.on("click",'[data-click="descriptionCollapse"]', function (e) {
            e.preventDefault()
            description[0].style.maxHeight = null

            descriptionToggle.html($fn.find('[data-ref="descriptionExpand"]').html())
        })
    }
})(jQuery);
