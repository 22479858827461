var reinitFooTable;
(function($){
    var $fn = $(".comp_my_orders");

    if($fn.length){

        var orders = [];

        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/floatthead/2.0.3/jquery.floatThead.min.js").done(function () {

            reinitFooTable = function() {
                let interval;
                $('.lib--footable').footable({
                    empty: $('table.lib--footable').attr('data-empty-text'),
                    "on": {
                        "postdraw.ft.table": function(e, ft){
                            setTimeout(function () {
                                $('.lib--footable').floatThead({
                                    top: function () {
                                        if($(window).width() > 1279) {
                                            return $("#layout_header").outerHeight(false);
                                        }
                                        else {
                                            return 0;
                                        }
                                    },
                                    responsiveContainer: function ($table) {
                                        return $table.closest(".elm_table");
                                    }
                                });
                            },500);
                        },
                        "expand.ft.row": function (e, ft, row) {
                            var el = $(row.$details[0]);
                            var orderId = row.$el.find('[data-order]').data('order');

                            clearInterval(interval);
                            interval = setInterval(function(){
                                if (row.expanded === true) {
                                    clearInterval(interval);
                                    row.$details.find('.wrp_comp_controls').closest("tr").addClass("tr_download");
                                    if (row.$details.find('.wrp_comp_controls').children().length === 0) {
                                        row.$details.find('.wrp_comp_controls').closest("tr").hide();
                                    }
                                }
                            },1);

                            if(!orders[orderId]) {
                                $.ajax({
                                    url: row.$el.find('[data-link]').data('link')
                                }).done(function (payload) {
                                    row.$details.find('.footable-details').find('tbody').find('tr:last').find('td:last').html(payload.html);
                                    orders[orderId] = payload.html;
                                });
                            }

                            el.addClass("animate-in");
                        },
                        "collapse.ft.row": function (e, ft, row) {
                        }
                    }
                });
            };
            reinitFooTable();
        });
        
        doc.on('click', '[data-repeat]', function(e){
            e.preventDefault();

            var elm = $(this);

            naja.makeRequest('POST', elm.attr('href')); 
        });
    }
})(jQuery);