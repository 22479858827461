(function($){
    const fn = $(".part_contact_banner");

    if (fn.length) {
        if (body[0].style["transform"] === "undefined") {
            fn.remove();
        }

        cssLoaded(function () {
            doc.on("click", "[data-contact-open]", function() {
                fn.addClass("mod--active");
            });

            fn.on("click", "[data-banner-close]", function() {
                fn_contact_banner_hide();
            });
        });

        function fn_contact_banner_hide() {
            fn.addClass("mod--not-active").removeClass("mod--active");
        }
    }
})(jQuery);