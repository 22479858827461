var checkAsEditLeaving = true
var originalAutoshipData = {};
var lastClickedReference = null;
(function ($) {
    var $fn = $(".comp_autoship_head");

    if ($fn.length) {

        var $notification = $('[data-notification]');
        if ($notification.length > 0) {
            $notification.each(function() {
                nl_lib_notification.open({
                    autohide: true,
                    state: $(this).data('state'),
                    title: $(this).data('title'),
                    text: $(this).data('text')
                });
            });
        }

        $(document).on('change','input[data-reason-input]', function() {

            if($('input[data-as-quit-other-reason-button]').prop('checked') === true) {
                $('div[data-as-quit-other-reason]').show();
            } else {
                $('div[data-as-quit-other-reason]').hide();
            }

        });

        $(document).on('click','[data-submit-as]', function(e) {
            e.preventDefault();

            var submitType = $(this).attr('data-submit-as');
            $('input[name="saveType"]').val(submitType);
            if($(this).is('[data-activate-order]')) {
                $('input[name="saveTypeActivateNewOrder"]').val($(this).attr('data-activate-order'));
            }

            $('form#autoshipForm').submit();

        });

        function getAsDataCurrentObject()
        {

            let mainItems = {};
            $('[data-special-item]').each(function() {
                let itemId = $(this).attr('data-special-item');
                if(typeof mainItems[itemId] === 'undefined') {
                    mainItems[itemId] = 1;
                } else {
                    mainItems[itemId]++;
                }
            });

            let otherItems = {};
            $('.part_item_basket_product[data-key]').each(function() {
                let itemId = $(this).attr('data-key');
                if(typeof otherItems[itemId] === 'undefined') {
                    otherItems[itemId] = 1;
                } else {
                    otherItems[itemId]++;
                }
            });

            let deliveryId = $('[data-set-shipping]:checked').val();
            let paymentId = $('[data-set-payment]:checked').val();

            let shippingDay = $('[name="autoship_day"]:checked').val();

            let addressId = null;

            if($('.part_item_address:visible').length) {
                addressId = $('.part_item_address.mod--active').attr('data-id');
                if(!addressId || typeof addressId === 'undefined') {
                    addressId = null;
                }
            }

            return {
                mainItems: mainItems,
                otherItems: otherItems,
                deliveryId: deliveryId,
                paymentId: paymentId,
                shippingDay: shippingDay,
                addressId: addressId
            };

        }

        function checkAsDataChanged()
        {
            let changed = false;

            let currentData = getAsDataCurrentObject();

            console.log('current data');
            console.log(currentData);
            console.log('original data');
            console.log(originalAutoshipData);

            let keysMainOriginal = Object.keys(originalAutoshipData.mainItems);
            let keysMainCurrent = Object.keys(currentData.mainItems);

            let keysOtherOriginal = Object.keys(originalAutoshipData.otherItems);
            let keysOtherCurrent = Object.keys(currentData.otherItems);

            if(!keysMainOriginal.equals2(keysMainCurrent)) {
                changed = true;
            }
            if(!keysOtherOriginal.equals2(keysOtherCurrent)) {
                changed = true;
            }
            if(currentData.deliveryId !== originalAutoshipData.deliveryId) {
                changed = true;
            }
            if(currentData.paymentId !== originalAutoshipData.paymentId) {
                changed = true;
            }
            if(currentData.shippingDay !== originalAutoshipData.shippingDay) {
                changed = true;
            }
            if(currentData.addressId && currentData.addressId !== originalAutoshipData.addressId) {
                changed = true;
            }

            return changed;
        }

        $(document).on('click','#submitAsOrderButton', function() {
            if($('.comp_autoship_head').is('[data-as-preactive]')) {
                checkAsEditLeaving = false;
            }
        });

        $(document).on('click','[data-submit-as]', function() {
            checkAsEditLeaving = false;
        });

        $(document).on('click','a', function(e) {

            if($(this).attr('href') === '#' || $(this).is('#submitAsOrderButton') || $(this).is('[data-as-novalidate]')) {
                return;
            }

            if($(this).closest('form#autoshipForm').length) {
                return;
            }

            if($('.comp_autoship_head').is('[data-as-novalidate]')) {
                return;
            }

            if(!$('.comp_autoship_head').is('[data-as-preactive]') && !$('.comp_autoship_head').is('[data-as-edit]')) {
                return;
            }

            var itemsChanged = checkAsDataChanged();

            if(($('.comp_autoship_head').is('[data-as-edit]') && itemsChanged) || ($('.comp_autoship_head').is('[data-as-preactive]') && itemsChanged)) {

                lastClickedReference = $(this);
                /*checkAsEditLeaving = false;*/

                if($('.comp_autoship_head').is('[data-as-preactive]')) {
                    naja.makeRequest('POST', '?do=dialogLeaving&isActivation=1', {}, {history: false});
                } else {
                    naja.makeRequest('POST', '?do=dialogLeaving', {}, {history: false});
                }

                e.preventDefault();
                return false;
            }
        });

        $(document).on('click','[data-as-dont-save]', function(e) {

            if(lastClickedReference) {
                checkAsEditLeaving = false;
                lastClickedReference.attr('data-as-novalidate','1');
                lastClickedReference[0].click();
            }

            e.preventDefault();
            return false;
        });

        $(document).on('click','[data-as-save]', function(e) {

            var asSubmitButton = $('#submitAsOrderButton');

            if(asSubmitButton.length) {
                asSubmitButton.attr('data-as-novalidate','1');
                asSubmitButton[0].click();
            }

            e.preventDefault();
            return false;
        });

        window.addEventListener('beforeunload', function(e) {

            var itemsChanged = checkAsDataChanged();

            console.log('items changed');
            console.log(itemsChanged);

            if($('.comp_autoship_head').is('[data-as-preactive]') || $('.comp_autoship_head').is('[data-as-novalidate]')) {
                itemsChanged = false;
            }

            if((itemsChanged || $('.comp_autoship_head').is('[data-as-preactive]')) && checkAsEditLeaving) {

                let leavingText = $(".comp_autoship_head").attr('data-leaving-text');
                e.preventDefault();

                e.returnValue = leavingText;
            }

            if(!checkAsEditLeaving) {
                checkAsEditLeaving = true;
            }

        }, {capture: false});

        $(document).ready(function() {
            originalAutoshipData = getAsDataCurrentObject();
        });

    }
})(jQuery);
