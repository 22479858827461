(function($){
    var $fn = $(".comp_materials_detail");

    if($fn.length){
        let slider = $fn.find("[data-slider]");
        let toc = $fn.find("[data-ref-toc]");

        if(slider) {
            let counter = $fn.find("[data-slider-counter]");

            $.getScript(cdnjs.flickity).done(function () {
                slider.flickity({
                    cellAlign: 'left',
                    contain: true,
                    pageDots: false,
                    on : {
                        change: function (index) {
                            counter.text(index + 1);
                        },

                        dragStart: function () {
                            [...slider.data("flickity").slider.childNodes].forEach(slide=>{
                                slide.style.pointerEvents = "none";
                            })
                        },

                        dragEnd: function () {
                            [...slider.data("flickity").slider.childNodes].forEach(slide=>{
                                slide.style.pointerEvents = null;
                            })
                        }
                    }
                });
            });

            slider.lightGallery({
                thumbnail: true,
                selector: ".elm_gallery_item",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: false,
                download: true,
                autoplay: false,
                autoplayControls: false,
                share: false
            });
        }

        $fn.on("click","[data-toggle-toc]",function (e) {
            e.preventDefault();
            toc.toggleClass("mod--active");
        })
    }
})(jQuery);