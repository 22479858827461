(function ($) {
    var $fn = $(".comp_account");

    if ($fn.length) {
        doc.on('click', '[data-avatar-add]', function (e) {
            e.preventDefault();
            $('[data-avatar-input]').trigger('click');
        });

        doc.on('click', '[data-avatar-save]', function (e) {
            e.preventDefault();
            naja.makeRequest('POST', location.href, {do: 'saveAvatar', binary: $('#cropper').cropper('getCroppedCanvas').toDataURL('image/jpeg', 0.9)});
            nl_lib_dialog.close();
        });

        doc.on('click', '[data-avatar-delete]', function (e) {
            e.preventDefault();
            naja.makeRequest('POST', location.href, {do: 'deleteAvatar'});
            nl_lib_dialog.close();
        });

        doc.on('change', '[data-avatar-input]', function () {
            if (this.files && this.files[0]) {
                var $cropper = $('#cropper');

                var reader = new FileReader();
                reader.onload = function (e) {
                    if ($cropper.data('cropper') !== undefined) {
                        $cropper.data('cropper').replace(e.target.result);
                    } else {
                        var img = $(`<img id="cropper" src="${e.target.result}">`);
                        $('[data-avatar-cropper-wrapper]').html(img);
                        createCropper();
                    }
                };
                reader.readAsDataURL(this.files[0]);
            }
        });

        doc.on("click", ".elm_avatar", function () {
            naja.makeRequest('GET', location.href, {do: 'dialogAvatar'});

            return;

            var $this = $(this),
                $file = $this.find("[type=file]"),
                $active = $fn.find(".elm_avatar.mod--active"),
                $setting = $this.data("option");

            var $avatar = $fn.find("[data-avatar-selected]");

            if ($file.length) {
                var $img = $this.find("img");
                $file.change(function () {
                    if (this.files && this.files[0]) {
                        var reader = new FileReader();

                        reader.onload = function (e) {
                            $img.attr('src', e.target.result);
                        };
                        reader.readAsDataURL(this.files[0]);

                        $img.parents(".elm_image").css("display", "block");

                        $active.removeClass("mod--active");
                        $this.addClass("mod--active").find(".wrp_avatar_upload").addClass("mod--loaded");
                        $avatar.val($setting);
                    }
                });
                if ($this.find(".wrp_avatar_upload.mod--loaded").length) {
                    $active.removeClass("mod--active");
                    $this.addClass("mod--active");
                    $avatar.val($setting);
                }
            } else {
                $active.removeClass("mod--active");
                $this.addClass("mod--active");
                $avatar.val($setting);
            }
        });

        doc.on("click", "[data-delete-avatar]", function () {
            var $this = $(this),
                $img = $this.prev("img");

            var $avatar = $fn.find("[data-avatar-selected]");

            // $this.fadeOut(200,function () {
            //     $this.remove();
            //     $img.initial({
            //         charCount: 2,
            //         height: 165,
            //         width: 165
            //     });
            //     $avatar.val(0);
            // })

            $img.initial({
                charCount: 2,
                height: 165,
                width: 165
            });
            $avatar.val(0);
        });

        var $img_default = $fn.find(".mod--default");

        if ($img_default.length) {
            $img_default.initial({
                charCount: 2,
                height: 165,
                width: 165
            })
        }
    }
})(jQuery);