(function($) {
    var $fn = $("#layout_header");
    if ($fn.length && $fn.find(".wrp_header_shop").length === 0) {
        var $header = $(document).find("#layout_menu");

        $fn.after('<nav id="layout_nav" class="ssm-nav" role="presentation"><div class="elm_head"></div><div class="elm_content"></div></nav>');

        var $layout_nav = $(document).find("#layout_nav"),
            $logo = $header.find(".wrp_logo").clone(),
            $nav = $header.find(".wrp_menu").clone(),
            $credits = $header.find(".wrp_credits").clone(),
            $admin = $header.find(".wrp_admin").clone();

        $layout_nav.find(".elm_head").append($logo);
        $layout_nav.find(".elm_content").append($credits);
        $layout_nav.find(".elm_content").append($admin);
        $layout_nav.find(".elm_content").append($nav);

        $('.ssm-nav').slideAndSwipe();

        var headroom = new Headroom($fn[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();

        var $img_default = $fn.find(".mod--default");

        if($img_default.length){
            $img_default.initial({
                charCount: 2,
                height: 165,
                width: 165
            })
        }

        let $header_search = $fn.find(".col--search-area");
        let $header_search_results = $fn.find(".col--search-results");
        let $header_search_timeout = null

        $(document).on("click", "#layout_nav .wrp_menu a[href]", function () {
            document.body.classList.add('is--loading')
        })

        $(document).on("input", "[data-search-autocomplete]", function () {
            let $results_data = $header_search_results.find("[data-results-product]");

            $results_data.html("");
            $header_search.find(".icon--search").addClass("mod--loading");

            let searchQuery = $(this).val();

            clearTimeout($header_search_timeout)

            $header_search_timeout = setTimeout(function () {
                $.ajax({
                    method: 'post',
                    dataType: 'json',
                    data: {do: 'searchQuery',searchQuery: searchQuery},
                    cache: false
                }).done(function (data) {

                    if(typeof data.content !== 'undefined') {
                        $results_data.html(data.content);
                    } else {
                        $results_data.html("");
                    }

                    $header_search_results.fadeIn(300);
                    $header_search.find(".icon--search").removeClass("mod--loading");
                });
            }, 500)
        });
        $(document).find('[data-search-autocomplete]').each(function (index, elem) {
            if (elem.value) {
                $header_search.fadeIn(300);
                $header_search.find('input[type="text"]').focus();
                $(document).find('[data-search-autocomplete]').trigger('input');
            }
        });

        $(document).on("click", "[data-toggle-search]", function () {
            $header_search.fadeIn(300);
            $header_search.find('input[type="text"]').focus();
        });
        $html.on("click", function (e) {
            if ($(e.target).closest('#layout_header').length === 0) {
                $header_search.fadeOut(300);
                $header_search.find('input[type="text"]').val("").blur();
                $header_search_results.fadeOut();
            }
        });
        $(document).on("click", "[data-close-search]", function (e) {
            e.preventDefault();
            $header_search.fadeOut(300);
            $header_search.find('input[type="text"]').val("").blur();
            $header_search_results.fadeOut();
            // kvuli vynulovani promenne v session
            $.ajax({
                method: 'post',
                dataType: 'json',
                data: {do: 'searchQuery', searchQuery: null},
                cache: false
            });
        });

        $layout_nav.on("scroll", function(){
            localStorage.setItem("layout_nav_menu_position", $layout_nav.scrollTop());
        });

        if (localStorage.getItem('layout_nav_menu_position') !== null) {
            $layout_nav.scrollTop(localStorage.getItem('layout_nav_menu_position'));
        }

        $.getScript(cdnjs.progressbarjs).done(function () {
            let avatar = $fn.find(".col--user").find(".elm_image");

            if(avatar.length) {
                let bar = new ProgressBar.Circle(avatar.get(0),{
                    strokeWidth: 7,
                    easing: 'easeInOut',
                    duration: 2500,
                    trailWidth: 1,
                    svgStyle: null
                });

                $(bar.svg).addClass("elm_progress");
                $(bar.trail).removeAttr("stroke").addClass("elm_trail");

                if(typeof avatar.data("progress") !== 'undefined') {
                    $(bar.path).removeAttr("stroke").addClass("elm_fill stroke--"+avatar.data("progress").color);
                    bar.animate(avatar.data("progress").progress);
                }
            }
        });
    }
})(jQuery);
