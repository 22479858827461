let doc = $(document),
    win = $(window),
    html = $("html"),
    body = $("body"),
    lang = html.attr('lang');

var $doc = doc,
    $html = html,
    $body = body;

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function() {
        if (!html.hasClass("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function(e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                for(let registration of registrations) {
                    registration.unregister()
                }
            });
        }
    });
}

(function($) {
    $body.removeClass("mod--preload");
    setTimeout(function () {
        $body.addClass("mod--loaded");
        $.getStyle("https://hello.myfonts.net/count/3483da");
    }, 300);
})(jQuery);