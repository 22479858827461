(function($){
    var $fn = $("#layout_login");
    if($fn.length){
        $fn.on("click","[data-login-switch]",function () {
            var $panel = $(this).closest(".elm_content_form");
            $panel.removeClass("mod--active");
            $panel.siblings(".elm_content_form").addClass("mod--active");
        });

        if (win.width() < 960) {
            $fn.on("focus", "input", function(){
                let offset = $(".elm_content_form.mod--active").offset().top;
                $('html, body').animate({
                    scrollTop: offset
                }, 300);
            });
        } else {
            $("[data-autofocus]").focus();
        }

        doc.on('change','[data-login-country]',function(e) {
            var toLocation = $(this).val();

            //var ajaxLink = $(this).attr('data-ajax-link');

            window.location.href = toLocation;

            /*naja.makeRequest('POST', ajaxLink,{toLocation: toLocation}).then(function(resp) {

             window.location.href = toLocation;
             });*/

        });

        doc.on('change','input[name="remember"]',function() {
            if(typeof secureStorage !== 'undefined' && secureStorage && $(this).prop('checked') === false) {
                secureStorage.get(
                    function (value) {
                        if (value === '1') {
                            $('[name="login"]').val('');
                            $('[name="password"]').val('');
                        }
                    },
                    function (error) {

                    },
                    "gwloginremember"
                );
            }


            if (typeof window.Capacitor !== 'undefined' && $(this).prop('checked') === false) {
                localStorage.removeItem('saveLogin')
                localStorage.removeItem('saveLoginPassword')
                $('[name="login"]').val('');
                $('[name="password"]').val('');
            }
        });
    }
})(jQuery);
