function fn_lib_notification() {
    var $body = $("body");

    function fn_lib_notification_show(data) {
        var date = new Date();
        var $id = $(document).find(".lib--notification .part_ui_notification").length;
        var $uid = date.getTime() + date.getMilliseconds() + $id;

        var $tpl = '<div class="part_ui_notification mod--state-'+ data.state +'" data-id="'+$uid+'"><button class="elm_close icon--close" data-notification-close></button>' +
            '<div class="wrp_part_body mod--row mod--row-flex"><div class="col"><strong class="elm_title">'+ data.title +'</strong></div><div class="col"><span class="elm_text">'+ data.text +'</span></div></div>' +
            '</div>';

        // $(document).find(".lib--notification .part_ui_notification").remove();
        if (!$(document).find(".lib--notification").length) {
            $body.append('<div class="lib--notification"></div>');
        }

        $(document).find(".lib--notification").append($tpl);

        setTimeout(function(){
            $(document).find(".lib--notification .part_ui_notification").filter('[data-id="'+$uid+'"]').addClass("mod--animate-in").show();
        },500*($id/2));

        if (data.autohide) {
            setTimeout(function(){
                fn_lib_notification_hide($uid);
            },14000*(($id+1)/2));
        }
    }

    function fn_lib_notification_hide(id) {
        $(document).find(".lib--notification .part_ui_notification").filter('[data-id="'+id+'"]').addClass("mod--animate-out");
        setTimeout(function(){
            $(document).find(".lib--notification .part_ui_notification").filter('[data-id="'+id+'"]').slideUp(300);
            setTimeout(function () {
                $(document).find(".lib--notification .part_ui_notification").filter('[data-id="'+id+'"]').remove();
                if ($(document).find(".lib--notification .part_ui_notification").length < 1) {
                    $(document).find(".lib--notification").remove();
                }
            }, 300);
        },400);
    }

    function fn_lib_notification_hide_all() {
        $(document).find(".lib--notification .part_ui_notification").addClass("mod--animate-out");
        setTimeout(function(){
            $(document).find(".lib--notification .part_ui_notification").slideUp(300);
            setTimeout(function () {
                $(document).find(".lib--notification .part_ui_notification").remove();
                if ($(document).find(".lib--notification .part_ui_notification").length < 1) {
                    $(document).find(".lib--notification").remove();
                }
            }, 300);
        },400);
    }

    $(document).on("click", "[data-notification-close]", function(){
        var id = $(this).closest(".part_ui_notification").data("id");
        fn_lib_notification_hide(id);
    });

    return {
        open: fn_lib_notification_show,
        hideAll: fn_lib_notification_hide_all
    };
}
var nl_lib_notification = fn_lib_notification();