var initRadioValidation;
(function($) {

    $.fn.radioValidation = function (opts) {

        $(this).removeClass("mod--invalid mod--valid");

        let radio = $(this).find('input[type="radio"]');
        let radioName = radio.attr('name');

        let emptyRadio = $('input[type="radio"][name="' + radioName + '"]:checked').length <= 0;

        if (!emptyRadio) {
            $(this).addClass("mod--valid");
        } else {
            $(this).addClass("mod--invalid");
        }
    };

    $(document).on("change", ".part_ui_radio.mod--required", function () {
        if (!$(this).filter("[data-no-validation]").length) {
            $(this).radioValidation();
        }
    });

    initRadioValidation = function()
    {
        $(".part_ui_radio.mod--required[data-init-validation]").each(function () {
            if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid")) {
                $(this).radioValidation({
                    initial: true
                });
            }
        });
    };
    initRadioValidation();

})(jQuery);