(function($) {
    var $fn = $("#layout_menu, #layout_header");
    if ($fn.length) {
        if($fn.find(".wrp_menu_admin").length) {
            let partners = $($fn.find(".wrp_menu_admin").find("[data-eac]"));

            // partners.easyAutocomplete({
            //     url: function (phrase) {
            //         return "?do=searchUser&phrase=" + phrase;
            //     },
            //     getValue: "id",
            //     template: {
            //         type: "custom",
            //         method: function (value, item) {
            //             return item.name;
            //         }
            //     },
            //     ajaxSettings: {
            //         dataType: "json",
            //         method: "POST",
            //         data: {
            //             dataType: "json"
            //         }
            //     },
            //     requestDelay: 400
            // });
        }

    }
})(jQuery);