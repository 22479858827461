/**
 * Created by zoich on 11.04.2018.
 */
(function($){
    var $fn = $(".comp_magazine");
    if($fn.length) {

        $fn.on('click','[data-app-download]',function(e) {

            if((typeof cordova !== 'undefined' && typeof cordovaDeviceReady !== 'undefined' && cordovaDeviceReady === true) || window.Capacitor) {
                linkDownloadFileCordova($(this));
                e.preventDefault();
            }

        });

        $.ajax({
            method : 'post',
            dataType: 'json',
            data: {
                do: 'compressFiles'
            }
        }).done(function(data) {

        });


    }
})(jQuery);
