(function($){
    var $fn = $(".comp_home_top");

    if($fn.length){
        $fn.on("click","[data-message-hide]",function () {
            var $wrp_message = $fn.find(".wrp_message");
            $wrp_message.addClass("mod--animation-hide").slideUp(500,function () {
                $wrp_message.remove();
            })
        });

        // var $dialog = $fn.find("[data-important-dialog]");
        // if($dialog.length){
        //     $dialog.click();
        // }
    }
})(jQuery);