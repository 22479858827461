var updateDashboardCountUps;
var updateDashboardProgressBars;
var updateDashboardDatePicker;


window.onpopstate = function(event) {
    setTimeout(function() {

        if($('div.part_dashboard_position').length && !$('div.part_dashboard_position').is('[data-reveal="revealed"]')) {
            var reqUri = $('section.comp_dashboard').attr('data-dashboard-ajax-link');
            if(!reqUri) {
                return;
            }
            naja.makeRequest('POST', reqUri, {},{history: false}).then(function(data) {
                if(typeof updateDashboardCountUps !== 'undefined' && updateDashboardCountUps) {
                    updateDashboardCountUps();
                }

                if(typeof updateDashboardProgressBars !== 'undefined' && updateDashboardProgressBars) {
                    updateDashboardProgressBars();
                }
            });
        }
    },1000);
};

(function ($) {
    let fn = $(".comp_dashboard");

    if (fn.length) {

        window.addEventListener('touchmove', (e) => {
            return true;
        }, { passive: false });
        if (fn.find("[data-sort-dashboard]").length) {
            $.getScript(cdnjs.sortable).done(function () {
                let sortable;
                fn.on("click", "[data-toggle-sort]", function () {
                    if (!fn.hasClass("mod--draggable")) {
                        fn.addClass("mod--draggable");
                        sortable = Sortable.create(fn.find("[data-sort-dashboard]")[0], {
                            group: "dashboard",
                            animation: 150,
                            store: {
                                get: function () {
                                    return [];
                                },
                                set: function (sortable) {
                                    naja.makeRequest('POST', location.href, {items: sortable.toArray(), do: 'sortBoxes'});
                                }
                            }
                        });
                    } else {
                        fn.removeClass("mod--draggable");
                        sortable.destroy()
                    }
                });
            });
        }
        if (fn.find(".part_dashboard_faq").length) {
            fn.find(".part_dashboard_faq").nl_lib_switch();
        }
        if (fn.find(".part_dashboard_position").length) {
            fn.find(".part_dashboard_position .wrp_body_levels").children().each(function(i){
                $(this).children(".elm_bar").css("transition-delay", `${0.2 * i}s`);
                // $(this)
            });
        }
        if (fn.find(".part_dashboard_switch_user").length) {
            let partners = $(fn.find(".part_dashboard_switch_user").find("[data-eac]"));

            // partners.easyAutocomplete({
            //     url: function (phrase) {
            //         return "?do=searchUser&phrase=" + phrase;
            //     },
            //     getValue: "id",
            //     template: {
            //         type: "custom",
            //         method: function (value, item) {
            //             return item.name;
            //         }
            //     },
            //     ajaxSettings: {
            //         dataType: "json",
            //         method: "POST",
            //         data: {
            //             dataType: "json"
            //         }
            //     },
            //     requestDelay: 400
            // });
        }

        $.getScript(cdnjs.countup).done(function () {
            updateDashboardCountUps = function() {
                $(".comp_dashboard").find("[data-countup]").each(function () {
                    let el = $(this);
                    let parent = el.closest("[data-reveal]");
                    let interval;
                    let duration = 3;
                    let decimals = 0;
                    if(el.data('decimals')) {
                        decimals = parseInt(el.data('decimals'));
                    }

                    let options = {
                        separator: ' ',
                        decimal: '.',
                        suffix: (el.data('countup').suffix) ? " " + el.data('countup').suffix : "",
                    };

                    if(parseInt(el.data("countup").value) > 100000) {
                        duration = 1.5;
                    }

                    let counter = new CountUp(el.get(0),0,parseFloat(el.data("countup").value),decimals,duration,options);

                    interval = setInterval(function () {
                        if(parent.attr("data-reveal") === "revealed") {
                            clearInterval(interval);
                            if (!counter.error) {
                                counter.start();
                            } else {
                                console.error(counter.error);
                            }
                        }
                    },150);
                });
            };
            updateDashboardCountUps();
        });

        let card_level = $(".part_dashboard_level");

        if(card_level.length) {
            $.getScript(cdnjs.progressbarjs).done(function () {

                updateDashboardProgressBars = function() {
                    let bar_wrap = $(".part_dashboard_level").find(".elm_body_bar");
                    let bar = new ProgressBar.Circle(bar_wrap.get(0),{
                        strokeWidth: 8,
                        easing: 'easeInOut',
                        duration: 2500,
                        trailWidth: 8,
                        svgStyle: null
                    });

                    $(bar.svg).addClass("elm_progress");
                    $(bar.trail).removeAttr("stroke").addClass("elm_trail");
                    $(bar.path).removeAttr("stroke").addClass("elm_fill " + (typeof bar_wrap.data("progress").color !== 'undefined' ? bar_wrap.data("progress").color : ''));


                    let interval;
                    interval = setInterval(function () {
                        if(card_level.attr("data-reveal") === "revealed") {
                            clearInterval(interval);
                            bar.animate(bar_wrap.data("progress").progress);
                        }
                    },150);
                };
                updateDashboardProgressBars();
            });
        }
    }
})(jQuery);