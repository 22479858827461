(function ($) {
    var $fn = $(".comp_autoship_addresses");

    if ($fn.length) {

        $(document).on("click", ".comp_autoship_addresses .part_item_address", function () {
            $(".comp_autoship_addresses").find(".mod--active").removeClass("mod--active");
            $(this).addClass("mod--active");

            var ajaxLink = $(this).attr('data-ajax-link');
            if(ajaxLink) {
                naja.makeRequest('POST', ajaxLink, {}, {history: false});
            }

        });

        function addResize() {
            $(".comp_autoship_addresses").find(".wrp_comp_add").css("min-height", $(".comp_autoship_addresses").find(".part_item_address").first().outerHeight());
        }

        addResize();

        $(window).on("resize", function () {
            addResize();
        });

        function showHideAsAddresses()
        {
            var isBranchAddress = ($('input[name="shipping"]:checked').is('[data-has-branch]') ? true : false);
            if(isBranchAddress) {
                $('.comp_autoship_addresses').slideUp();
            } else{
                $('.comp_autoship_addresses').slideDown();
            }
        }

        $(document).on('change','input[name="shipping"]',function() {
            showHideAsAddresses();
        });
        showHideAsAddresses();

    }
})(jQuery);