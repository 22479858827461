(function ($) {
    var $fn = $(".comp_autoship_other_products");

    if ($fn.length) {

        $(document).on("input","[name=\"autoship_filter\"]",function () {

            //Ignorovat, udelat AJAXem jako naseptavace!!!!

            // var $dialog = $(document).find(".part_dialog_autoship"),
            //     $content = $dialog.find(".wrp_part_content"),
            //     $val = $(this).val();
            //
            // $content.find(".part_item_autoship_add").each(function () {
            //     var $this = $(this),
            //         $name = $this.find(".elm_name i");
            //
            //     if($name.text().indexOf($val) >= 0){
            //         $this.show();
            //     }
            //     else {
            //         $this.hide();
            //     }
            // })
        });


        //celková cena za produkt/mmozstvi v other other products autoship
        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/numeral.js/2.0.6/numeral.min.js").done(function () {

            numeral.localeData().delimiters.thousands = ' ';

            if($('html').data('currency') == 'eur') {
                numeral.defaultFormat('0,0.00');
            } else if($('html').data('currency') == 'czk') {
                numeral.defaultFormat('0,0');
            } else if($('html').data('currency') == 'pln') {
                numeral.defaultFormat('0,0.00');
            }


            $(document).on('click','.part_item_autoship_add [data-number]', function() {

                if(typeof otherProductsChangePlus !== 'undefined') {
                    if($(this).attr('data-number') === 'plus') {
                        otherProductsChangePlus = true;
                    } else {
                        otherProductsChangePlus = false;
                    }
                }

                var parent = $(this).closest('.part_item_autoship_add');
                var count = parent.find('input[data-secondary-count]').val();
                var default_unit_price = parent.find('[data-unit-price]').data('unit-price');
                var unit_price = default_unit_price;
                var price_elm = parent.find('span[data-price]');

                if (count >= 5 && parent.find('[data-unit-price3]').length) {
                    var unit_price_discounted = parent.find('[data-unit-price3]').data('unit-price3');
                    var spare_pieces = count % 5;
                    var total_price = (count - spare_pieces) * unit_price_discounted + spare_pieces * default_unit_price;

                    unit_price = total_price / count;
                }

                if (count >= 10 && parent.find('[data-unit-price2]').length) {
                    var unit_price_discounted = parent.find("[data-unit-price2]").data("unit-price2");
                    var spare_pieces = count % 10;
                    var total_price = (count - spare_pieces) * unit_price_discounted + spare_pieces * default_unit_price;

                    unit_price = total_price / count;
                }

                price_elm.text(numeral(count*unit_price).format());

            });
        });
    }
})(jQuery);