var selectBalikobox;

//balík na poštu cz/sk

(function($){

    var $fn = $(".elm_select_branches_balikobox");

    function SelectBalikoboxClass()
    {

        var _this = this;
        this.countryCode = null;
        this.selectElem = null;

        this.init = function()
        {

            this.selectElem = $('#balikobox_select');
            this.countryCode = $('.elm_select_branches_balikobox').attr('data-country');

            function initTippyBalikNaPostu()
            {
                const template = document.querySelector('#balikoboxOpenHours');
                var initialText;
                if(template !== null && template) {
                    initialText = template.textContent;
                } else {
                    initialText = '...';
                }

                var tip = tippy('.openingHoursBalikobox', {
                    animation: 'shift-toward',
                    arrow: true,
                    html: '#balikNaPostuOpenHours',
                    onShow(instance) {
                        // `this` inside callbacks refers to the popper element
                        var content = this.querySelector('.tippy-content');

                        var customUrl = $(instance.reference).attr('data-url');

                        if (tip.loading || content.innerHTML !== initialText) return;

                        tip.loading = true;

                        $.ajax({
                            url: customUrl,
                            dataType: 'html'
                        }).done(function(data) {
                            content.innerHTML = data;
                            tip.loading = false
                        });

                    },
                    onHidden() {
                        const content = this.querySelector('.tippy-content');
                        content.innerHTML = initialText
                    },
                    // prevent tooltip from displaying over button
                    popperOptions: {
                        modifiers: {
                            preventOverflow: {
                                enabled: false
                            },
                            hide: {
                                enabled: false
                            }
                        }
                    }
                });
            }

            $(window).on('load',function() {
                initTippyBalikNaPostu();
            });

            function selectTemplateBalikNaPostu(data, container) {
                var branchId = data.id;
                var city = data.city;
                var city_part = data.city_part;
                var address = data.address;
                var zip = data.zip;
                var id_db = data.id_db;

                if(city == city_part) {
                    city_part = '';
                } else {
                    city_part = ' ' + city_part;
                }

                if (!branchId) {
                    return "";
                } else {
                    return $(
                        "<div class='balikovna-address-content'><div class='cityPart'>" + city + ",</div>" +
                        "<div class='address'>&nbsp;" + address + city_part +  ",</div>" +
                        "<div class='kind'>&nbsp;" + zip + "</div></div>" +
                        "<div class='openingHoursBalikobox tooltipTrigger icon icon--clock' data-url='?do=balikoboxGetOpenins&bId="+ id_db +"&countryCode=" + _this.countryCode + "'>" + "" + "</div>"
                    );
                }
            }

            var searchBalikNaPostu;

            var balikNaPostuSelect = _this.selectElem;

            balikNaPostuSelect.select2({
                language: "cs",
                ajax: {
                    url: "?do=searchBalikobox&countryCode=" + _this.countryCode,
                    data: function (params) {
                        searchBalikNaPostu = params.term;
                        return {
                            q: params.term
                        };
                    },
                    processResults: function (data) {
                        setTimeout(function () {
                            initTippyBalikNaPostu();
                        }, 300);
                        return {
                            results: $.map(data.branches, function (item) {
                                return {
                                    id: item.id,
                                    text: item.name,
                                    city: item.city,
                                    city_part: item.city_part,
                                    address: item.address,
                                    zip: item.zip,
                                    id_db: item.id_db
                                }
                            })
                        };
                    },
                    complete: function () {
                        if (searchBalikNaPostu !== undefined) {
                            setTimeout(function () {
                                initTippyBalikNaPostu();
                            }, 100);
                        }
                    }
                },
                minimumInputLength: 1,
                templateResult: selectTemplateBalikNaPostu,
                templateSelection: selectTemplateBalikNaPostu
            });

            balikNaPostuSelect.on('select2:select',function() {
                setTimeout(function () {
                    initTippyBalikNaPostu();
                }, 100);
            });


        };

    }

    selectBalikobox = new SelectBalikoboxClass();

    if ($fn.length) {
        selectBalikobox.init();
    }

})(jQuery);
