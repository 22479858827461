function libGallery(elm) {
    elm.lightGallery({
        thumbnail: true,
        selector: ".elm_gallery_item",
        exThumbImage: 'data-exthumbimage',
        fullScreen: false,
        zoom: true,
        actualSize: false,
        hash: false,
        download: true,
        autoplay: false,
        autoplayControls: false,
        share: false
    });
}