var reinitFooTable;
(function($){
    var $fn = $(".comp_leads");

    if($fn.length){
        reinitFooTable = function() {
            let interval;
            $('.lib--footable').footable({
                empty: $('table.lib--footable').attr('data-empty-text')
            });
        };
        reinitFooTable();
    }
})(jQuery);
