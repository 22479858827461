var reinitBasketButtons = null;

(function($){
    var $fn = $(".comp_basket");
    if($fn.length){
        $fn.find(".part_form_basket_data [data-toggle] input").on("change", function () {
            $(this).closest("[data-toggle]").next(".mod--row").slideToggle(300);
        });

        var $notification = $('[data-notification]');
        if ($notification.length > 0) {
            $notification.each(function() {
                nl_lib_notification.open({
                    autohide: true,
                    state: $(this).data('state'),
                    title: $(this).data('title'),
                    text: $(this).data('text')
                });
            });
        }

        $fn.on("change",".elm_payment .part_ui_radio",function () {
            let el = $(this);
            let partner = $fn.find(".elm_partner");

            if(el.is("[data-partner]")) {
                partner.fadeIn();
            }
            else {
                partner.fadeOut();
            }
        });

        if($('input[name="payment"][data-code="bank"]').prop('checked') === true) {
            let partner = $fn.find(".elm_partner");
            partner.fadeIn();
        }

        reinitBasketButtons = function() {
            var partners = $(".comp_basket").find("[data-partners]");
            if($(".comp_basket").length && partners.length) {
                partners = $(partners[0]);

                partners.easyAutocomplete({
                    /*data: partners.data("partners"),*/
                    url: function(phrase) {
                        return "?do=searchHierarchy";
                    },
                    getValue: function(element) {
                        return element.name;
                        /*return element.id;*/
                    },
                    list: {
                        match: {
                            enabled: true
                        },
                        onSelectItemEvent: function() {

                            var selectedItemId = partners.getSelectedItemData().id;

                            $("input#orderToPartner").val(selectedItemId);
                        }
                    },
                    ajaxSettings: {
                        dataType: "json",
                        method: "POST",
                        data: {
                            dataType: "json"
                        }
                    },
                    preparePostData: function(data) {
                        data.phrase = partners.val();
                        return data;
                    },
                    requestDelay: 400
                });

                $(".comp_basket").off("click.partnersToggle","[data-partners-toggle]");

                $(".comp_basket").on("click.partnersToggle","[data-partners-toggle]",function (e) {
                    e.preventDefault();
                    if(partners.is(":visible")){
                        partners.stop().blur().fadeOut();
                    }
                    else {
                        $('html, body').animate({
                            scrollTop: html.outerHeight()
                        }, 500);
                        partners.stop().fadeIn().focus();
                    }
                })
            }
        };
        reinitBasketButtons();

        function togglePartnerDeliveryAddress()
        {
            let input = $('[data-toggle-partner-addresses] input');

            if(input.prop('checked') === true) {
                $('[data-toggle-address-partner]').hide();
                $('[data-toggle-address-partner] select').attr('disabled','disabled');

                $('[data-toggle-address-self]').show();
                $('[data-toggle-address-self] select').removeAttr('disabled');
            } else {
                $('[data-toggle-address-self]').hide();
                $('[data-toggle-address-self] select').attr('disabled','disabled');

                $('[data-toggle-address-partner]').show();
                $('[data-toggle-address-partner] select').removeAttr('disabled');
            }
        }

        $(document).on('change','[data-toggle-partner-addresses] input',function() {
            togglePartnerDeliveryAddress();
        });

        if($('[data-toggle-partner-addresses] input').length) {
            togglePartnerDeliveryAddress();
        }

        $(window).on("load", function() {
            if($('.comp_basket').is('[data-choose-free]')) {
                var dUrl = $('.comp_basket').attr('data-choose-free');
                naja.makeRequest('POST', dUrl,{},{history: false});
            }
        });

        $(document).on('change','[data-free-basket]',function() {
            checkMaxFreeProductsAdd($(this));
        });

        $(document).on('click','[data-dialog-add-free-products]', function() {

            body.addClass("is--loading");
            if ($("#layout_nav").hasClass("ssm-nav-visible")) {
                $(".ssm-nav-visible").removeClass("ssm-nav-visible");
                $(".ssm-overlay").fadeOut(300);
                $("htm").css("overflow","visible");
            }

            var url = $('[data-add-free-products]').attr('data-add-free-products');
            var products = {};

            $('[data-free-basket]').each(function() {
                var productId = $(this).attr('data-product-id');
                var num = parseInt($(this).val()) || 0;
                if(num > 0) {
                    products[productId] = num;
                }
            });

            naja.makeRequest('POST',url,{products: products},{history: false});

        });

        function checkMaxFreeProductsAdd(currentInput) {

            var maxItems = parseInt($('[data-dialog-basket-special]').attr('data-dialog-basket-special')) || 0;

            var total = 0;
            $('[data-free-basket]').each(function() {
                total += parseInt($(this).val()) || 0;
            });

            if(total > maxItems) {
                var diff = total - maxItems;
                var currentInputVal = parseInt(currentInput.val()) || 0;
                var newVal = currentInputVal - diff;
                if(newVal >= 0) {
                    currentInput.val(newVal);
                }
            }
        }
    }
})(jQuery);