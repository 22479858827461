function libDatepicker(elm) {
    const options = JSON.parse(elm.dataset.libDatepicker)
    const languages = {
        en: {
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'MM/dd/yyyy',
            timeFormat: 'hh:mm aa',
            firstDay: 0
        },
        cs: {
            days: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
            daysShort: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
            daysMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
            months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
            monthsShort: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'],
            today: 'Dnes',
            clear: 'Vymazat',
            dateFormat: 'dd.MM.yyyy',
            timeFormat: 'HH:mm',
            firstDay: 1
        },
        sk: {
            days: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
            daysShort: ['Ned', 'Pon', 'Uto', 'Str', 'Štv', 'Pia', 'Sob'],
            daysMin: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'],
            months: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
            today: 'Dnes',
            clear: 'Vymazať',
            dateFormat: 'dd.MM.yyyy',
            timeFormat: 'HH:mm',
            firstDay: 1
        },
        hu: {
            days: ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'],
            daysShort: ['Va', 'Hé', 'Ke', 'Sze', 'Cs', 'Pé', 'Szo'],
            daysMin: ['V', 'H', 'K', 'Sz', 'Cs', 'P', 'Sz'],
            months: ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szep', 'Okt', 'Nov', 'Dec'],
            today: 'Ma',
            clear: 'Törlés',
            dateFormat: 'yyyy-MM-dd',
            timeFormat: 'hh:mm aa',
            firstDay: 1
        },
        pl: {
            days: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
            daysShort: ['Nie', 'Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob'],
            daysMin: ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
            months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
            monthsShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
            today: 'Dzisiaj',
            clear: 'Wyczyść',
            dateFormat: 'yyyy-MM-dd',
            timeFormat: 'hh:mm:aa',
            firstDay: 1
        }
    }

    let locale = languages['en']

    if(['cs','pl','hu','sk'].indexOf(document.documentElement.lang) > -1) {
        locale = languages[document.documentElement.lang]
    }

    const elmAlt = document.createElement('input')
    elmAlt.setAttribute('type','hidden')
    if(elm.getAttribute('name')) {
        elmAlt.setAttribute('name',elm.getAttribute('name'))
        elm.removeAttribute('name')
    }
    elm.setAttribute('type','text')
    elm.setAttribute('readonly','')
    elm.parentNode.insertBefore(elmAlt, elm.nextSibling);

    const optionsDefault = {
        locale: locale,
        altField: elmAlt,
        altFieldDateFormat: 'yyyy-MM-dd',
        autoClose: true,
        isMobile: window.innerWidth < 768,
        selectedDates: elm.value,
        buttons: ['today', 'clear']
    }

    $.getStyle('https://fastly.jsdelivr.net/npm/air-datepicker@3.3.5/air-datepicker.min.css')
    $.getScript('https://fastly.jsdelivr.net/npm/air-datepicker@3.3.5/air-datepicker.min.js').then(function () {
        new AirDatepicker(elm, Object.assign(optionsDefault, options))
    })
}
