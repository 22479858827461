(function ($) {
    var $fn = $(".comp_wsw_notice");

    if ($fn.length) {
        $fn.find("[data-anim-count]").prop('counter',999).stop(true).animate({
            counter: $fn.find("[data-anim-count]").text()
        }, {
            duration: 2000,
            easing: 'swing',
            step: function (now) {
                $fn.find("[data-anim-count]").text(Math.ceil(now));
            }
        });
    }
})(jQuery);