(function($){
    var $fn = $(".part_registration_data");

    var dataFormCurrentValues = {};
    var dataFormNewValues = {};

    if ($fn.length) {
        $fn.find("[data-toggle-edit]").closest(".wrp_part").find(".elm_input").each(function(){
            var $input = $(this),
                $input_elm = $input.find(".part_ui_input input"),
                $select_elm = $input.find(".part_ui_select select");

            if ($input_elm.length) {
                $input_elm.on("input", function() {
                    $input.find(".elm_input_text span").text($input_elm.val());
                });
            }

            if ($select_elm.length) {
                $select_elm.on("change", function() {
                    var $selected = $(this).find("option:selected");
                    $input.find(".elm_input_text span").text($selected.text());
                    if ($selected.filter("[data-class]").length) {
                        $input.find(".elm_input_text").removeClass("mod--color-success mod--color-error").addClass($selected.data("class"));

                    }
                });
            }
        });

        function toggleEditSection(buttonElem)
        {
            var $elm = buttonElem,
                $part = $elm.closest(".wrp_part");

            if (!$elm.hasClass("mod--save")) {
                $elm.addClass("mod--save");
                $part.find(".elm_input").each(function() {
                    var $input = $(this);
                    if ($input.find(".part_ui_input").length) {
                        $input.closest(".elm_item").addClass("mod--editing");
                        $input.find(".elm_input_text").hide();
                        $input.find(".part_ui_input").show();
                    }

                    if ($input.find(".part_ui_select").length) {
                        $input.closest(".elm_item").addClass("mod--editing");
                        $input.find(".elm_input_text").hide();
                        $input.find(".part_ui_select").show();
                    }
                });
            } else {
                var $input_empty;

                $part.find(".elm_input").each(function() {
                    var attrNoValidate = $(this).find('[data-no-validation]');
                    if ($(this).find("input").val() === ""  && !attrNoValidate.length) {
                        $input_empty = true;
                        return false;
                    } else {
                        $input_empty = false;
                    }
                });

                $part.find(".elm_input").each(function() {
                    var attrNoValidate = $(this).find('[data-no-validation]');
                    if ($(this).find("input").val() === "" && !attrNoValidate.length) {
                        $(this).find(".part_ui_input").addClass("mod--invalid");
                        $elm.addClass("mod--invalid");
                    }
                });

                if (!$input_empty) {
                    $elm.removeClass("mod--save");
                    $part.find(".elm_input").each(function() {
                        var $input = $(this);
                        if ($input.find(".part_ui_input").length) {
                            $input.find(".elm_input_text").show();
                            $input.find(".part_ui_input").removeClass("mod--invalid").hide();
                            $input.closest(".elm_item").removeClass("mod--editing");
                            $elm.removeClass("mod--invalid");
                        }

                        if ($input.find(".part_ui_select").length) {
                            $input.find(".elm_input_text").show();
                            $input.find(".part_ui_select").hide();
                            $input.closest(".elm_item").removeClass("mod--editing");
                        }
                    });
                }
            }
        };

        $fn.on("click", "[data-toggle-edit]", function() {
            toggleEditSection($(this));
        });

        if($('.part_registration_data[data-empty-user-data]').length) {
            toggleEditSection($('[data-user-data-edit-button]').children());
        }

        //fill current values

        function getDataFormValues(container)
        {
            $('form.part_form input[type="text"]').each(function() {
                var name = $(this).attr('name');
                var val = $(this).val();

                container[name] = val;
            });
        }

        function isSameFormData()
        {

            var allSame = true;
            $.each(dataFormCurrentValues, function(index,oldVal) {

                if(typeof dataFormNewValues[index] !== 'undefined' && dataFormNewValues[index] !== oldVal) {
                    allSame = false;
                }

            });
            return allSame;
        }

        getDataFormValues(dataFormCurrentValues);

        $fn.on('click','[data-toggle-edit].mod--save', function() {
            getDataFormValues(dataFormNewValues);

            if(!isSameFormData()) {

                $('[data-toggle-approved].mod--approved').trigger('click');

            } else {

            }

        });

        $fn.on('click','[data-toggle-approved]:not(.mod--approved)', function() {
            getDataFormValues(dataFormCurrentValues);
        });


        let birthdate = $fn.find("[data-birthdate]").find('input');
        $.getScript(cdnjs.inputmask).done(function () {
            birthdate.inputmask({
                alias: "datetime",
                inputFormat: "dd.mm.yyyy",
                placeholder: "dd.mm.rrrr"
            });
        })
    }
})(jQuery);
