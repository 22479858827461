(function($){
    var $fn = $(".comp_registration_create");

    if ($fn.length) {

        $fn.on("click","[data-nav-fake]",function () {
            var $el = $(this);
            $fn.find("[data-nav-fake]").removeClass("mod--active");
            $el.addClass("mod--active");

            $el.closest("[data-switch-registration]").find("[data-nav-item]").eq($el.data("nav-fake")).trigger("click");
        });

        $fn.on("click","[data-switch-registration] [data-nav-item]",function () {
            $fn.find("[data-nav-fake]").removeClass("mod--active");
            $fn.find("[data-nav-fake=\""+ $(this).closest("li").index() +"\"]").addClass("mod--active");
        });

        $(document).on('change','input#frm-ic',function() {
            var inpVal = $(this).val();
            inpVal = inpVal.toString();
            if(inpVal.length) {
                inpVal = inpVal.replace(/\s/g,'');
                $(this).val(inpVal);
            }
        });

        var continueCheckInvalid = true;

        setInterval(function() {
            if(!continueCheckInvalid) {
                continueCheckInvalid = true;
            }
        },2000);

        function initInputInvalid()
        {
            $('input[type="text"]').bind("invalid", function(e) {
                if(continueCheckInvalid) {
                    var _this = this;
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(_this).offset().top - 150
                    }, 500);
                    continueCheckInvalid = false;
                }
            });
        }
        initInputInvalid();

        var $form = $fn.find(".part_form");
        var $form_contract = $fn.find(".part_form_contract");
        var $form_collaboration = $fn.find(".part_form_collaboration");

        $fn.find("[data-switch-registration]").nl_lib_switch(function (n) {
            $form.find(".mod--required").each(function () {
                $(this).find("input,textarea,select").removeAttr("required");
            });
            if (n === 0) {
                $form_contract.find(".mod--required").each(function () {
                    $(this).find("input,textarea,select").attr("required", "");
                });
            } else {
                $form_collaboration.find(".mod--required").each(function () {
                    $(this).find("input,textarea,select").attr("required", "");
                });
            }
        });

        $fn.on("change", ".part_form [data-toggle-disabled]", function () {
            var $elm = $(this);

            if ($elm.prop("checked")) {
                $elm.closest(".wrp_form_head").next(".wrp_form_content").find(".part_ui_input, .part_ui_select").removeClass("mod--disabled").find('input:not([name="deliStreetAdd"]), select').removeAttr("disabled").attr("required", "required");
                $elm.closest(".wrp_form_head").next(".wrp_form_content").find(".part_ui_input, .part_ui_select").find('input[name="deliStreetAdd"]').removeAttr("disabled");
            } else {
                $elm.closest(".wrp_form_head").next(".wrp_form_content").find(".part_ui_input, .part_ui_select").addClass("mod--disabled").find('input, select').attr("disabled", "").removeAttr("required");
            }
        });

        if ($(".part_form [data-toggle-disabled]").prop("checked") === false) {
            $(".part_form [data-toggle-disabled]").closest(".wrp_form_head").next(".wrp_form_content").find(".part_ui_input, .part_ui_select").addClass("mod--disabled").find('input, select').attr("disabled", "").removeAttr("required");
        }

        //ares fill
        $fn.on('click', '[data-ares="do-fill"]', function () {
            fillAresData();
        });

        function fillAresData() {

            var $elm = $('input[data-ares-check]');

            $.ajax({
                method: 'post',
                dataType: 'json',
                data: {do: 'getAresData', ic: $elm.val(), country: $elm.closest('form').attr('data-country')}
            }).done(function (data) {
                if (typeof data['in'] !== 'undefined') {
                    nl_lib_notification.hideAll();
                    $('[data-ares-not-found]').hide();
                    $.each(data, function (index, val) {

                        if (index == 'street' && val === null) {
                            val = data['city'];
                        }

                        if (index == 'street' && typeof data['house_number'] !== 'undefined' && data['house_number'].length > 0) {
                            val = val + ', ' + data['house_number'];
                        }

                        if ($('[data-ares-fill="' + index + '"]').length) {
                            $('[data-ares-fill="' + index + '"]').val(val);
                        }

                        document.querySelectorAll('.mod--invalid').forEach(function (el) {
                            $(el).trigger('change')
                        });
                    });
                } else {
                    /*$('[data-ares-not-found]').show();*/
                    var $notification = $('[data-notification-ic]');
                    if ($notification.length > 0) {
                        nl_lib_notification.open({
                            autohide: true,
                            state: $notification.data('state'),
                            title: $notification.data('title'),
                            text: $notification.data('text')
                        });
                    }
                }

            });

        }

        function checkAutoshopSelected() {
            var displayASDates = false;
            $('input[name="autoship"]').each(function () {
                if ($(this).prop('checked') === true && $(this).val() == 'Y') {
                    displayASDates = true;
                }
            });

            if (displayASDates) {
                $('[data-autoship-date-section]').show();
            } else {
                $('[data-autoship-date-section]').hide();
            }
        }

        $fn.on('change', 'input[name="autoship"]', function () {
            checkAutoshopSelected();
        });
        checkAutoshopSelected();

        /*var $notification = $('[data-notification]');

        if ($notification.length > 0) {
            $notification.each(function() {
                nl_lib_notification.open({
                    /!*autohide: true,*!/
                    state: $(this).data('state'),
                    title: $(this).data('title'),
                    text: $(this).data('text')
                });
            });
        }*/

        $(document).on('submit', 'form.part_form_contract, form.part_form_collaboration', function () {
            $(this).find('button[type="submit"]').attr('disabled', 'disabled');
        });

        function changeCurrentFormCountry(selectedCountry, formType)
        {

            switch (formType) {
                case 'KS':
                    var formData = $('form.part_form_contract').serialize();
                    var currentForm = 'form.part_form_contract';
                    break;
                case 'OS':
                    var formData = $('form.part_form_collaboration').serialize();
                    var currentForm = 'form.part_form_collaboration';
                    break;
            }
            $.ajax({
                method: 'post',
                dataType: 'json',
                data: {do: 'changeRegCountry', country: selectedCountry, formType: formType, formData: formData}
            }).done(function (data) {

                $('[type="tel"]').intlTelInput({
                    nationalMode: false,
                    preferredCountries: ['cz', 'sk', 'pl'],
                    initialCountry: data.payload.customCountry,
                    customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                        return '+' + selectedCountryData.dialCode;
                    }
                });

                var deliCheck = $(currentForm + ' input[data-toggle-disabled]');

                if(deliCheck.prop("checked") === false) {
                    $(currentForm + "[deli-data-block]").find(".part_ui_input, .part_ui_select").addClass("mod--disabled");
                    $(currentForm + ' [data-deli-input]').attr("disabled", "disabled").removeAttr("required");
                    /*currentForm.find("[deli-data-block]").find(".part_ui_input, .part_ui_select").addClass("mod--disabled");
                    currentForm.find("[data-deli-input]").each(function() {
                        $(this).attr("disabled", "disabled").removeAttr("required");
                    });*/
                }

                checkPlatceDphCzDic();
                checkPlatceDphVisible();
                initInputInvalid();
                initInputValidation();
                initRadioValidation();
                initSelectValidation();

                let birthdate = $fn.find("[data-birthdate]").find('input');
                if(birthdate.length) {
                    $.getScript(cdnjs.inputmask).done(function () {
                        birthdate.inputmask({
                            alias: "datetime",
                            inputFormat: "dd.mm.yyyy",
                            placeholder: "dd.mm.rrrr"
                        });
                    })
                }

            });

        }

        $fn.on('change','select[name="regCountry"]',function() {
            var selectedCountry = $(this).val();
            var currentType = $(this).attr('data-type');
            $('select[name="regCountry"]').val(selectedCountry);

            changeCurrentFormCountry(selectedCountry, currentType);

            switch(currentType) {
                case 'KS':
                    changeCurrentFormCountry(selectedCountry, 'OS');
                    break;
                case 'OS':
                    changeCurrentFormCountry(selectedCountry, 'KS');
                    break;
            }
        });

        let birthdate = $fn.find("[data-birthdate]").find('input');
        if(birthdate.length) {
            $.getScript(cdnjs.inputmask).done(function () {
                birthdate.inputmask({
                    alias: "datetime",
                    inputFormat: "dd.mm.yyyy",
                    placeholder: "dd.mm.rrrr"
                });
            })
        }

        if($('[data-form-country]').length) {
            let formCountry = $('[data-form-country]').attr('data-form-country');
            if(formCountry && formCountry.length) {
                let select = $('select[name="regCountry"]:visible');
                if(select.length) {
                    select.val(formCountry);
                }
            }
        }

    }
})(jQuery);
