(function($){
    var $fn = $(".comp_special");

    if($fn.length) {
        $fn.on("click","[data-special-remove]",function (e) {
            var $this = $(this);
            //vyhozeni z kosiku nechavam na implementatorovi
            $this.find(".wrp_filled").fadeOut(500,function () {
                $this.addClass("mod--unfilled");
                var $dialog = $this.data("dialog-hold");
                $this.data("dialog",$dialog).attr("data-dialog",$dialog);
            });
        });
    }
})(jQuery);