(function ($) {
    var $fn = $(".comp_cards");

    if ($fn.length) {

        var $notification = $('[data-notification]');
        if ($notification.length > 0) {
            $notification.each(function() {
                nl_lib_notification.open({
                    state: $(this).data('state'),
                    title: $(this).data('title'),
                    text: $(this).data('text')
                });
            });
        }

        // fill card data
        var form = $(".comp_cards form");
        var svg = $('[data-card-svg]');

        // $(".wrp_part_preview").stick_in_parent({
        //     offset_top: 80
        // });

        function fillCard() {
            form.find(':input').each(function (index, item) {
                var input = $(item);
                svg.find('[data-field="' + input.attr('name') + '"]').text(input.val());
            });
        }

        form.find(':input').on('input', fillCard);
        fillCard();

        // calculate price
        function calculatePrice() {
            var $elm = $('[name="count"]');

            $.ajax({
                method: 'post',
                dataType: 'json',
                data: {do: 'getTotalPrice', count: $elm.val()}
            }).done(function (data) {
                $('[data-price1]').text(data.total);
                $('[data-price2]').text(data.totalVat);

                $('[name="price"]').val(data.total);
                $('[name="priceVat"]').val(data.totalVat);
            });
        }

        $(document).on('change', '[name="count"]', calculatePrice);
        calculatePrice();

        form.on("click", '.elm_btn button', function () {
            var $input = form.find("input:invalid").first();
            $('html, body').animate({
                scrollTop: $input.offset().top - 120
            }, 0);
        })
    }
})(jQuery);