var loadBalikovnaSelect;
var handleSelectedDelivery;

(function ($) {
    var $fn = $(".comp_autoship_edit");

    if ($fn.length) {

        $(document).on('change','input[name="useCreditPoints"]',function() {
            var usePoints = $(this).prop('checked') ? 1 : 0;
            naja.makeRequest('POST', $(this).data('ajax-link'), {usePoints: usePoints});
        });

        $(document).on('click','[data-autoship-day]',function() {

            if($(this).hasClass('mod--disabled')) {
                return;
            }

            var day = $(this).prev('input').val();

            naja.makeRequest('POST', location.href, {do: 'setAsDay', day: day});
            
            /*
            $.ajax({
                method: 'post',
                dataType: 'json',
                data: {do: 'setAsDay', day: day}
            }).done(function (data) {

            });

            */
        });

        let autoship_toggle = $fn.find("[data-autoship-toggle]");
        if(autoship_toggle.length) {
            $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+lang+".min.js").done(function () {

                let now = new Date();
                if (now.getMonth() === 11) {
                    var currentMonth = new Date(now.getFullYear() + 1, 0, 1);
                } else {
                    var currentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
                }

                let dp = autoship_toggle.next("input").datepicker({
                    minDate: currentMonth,
                    view: "months",
                    minView: "months",
                    language: lang,
                    classes: "datepicker--autoship",
                    dateFormat: "yyyy/mm/dd",
                }).data("datepicker");

                let dpas = doc.find(".datepicker--autoship");
                dpas.prepend("<div class=\"datepicker--head\"><span>"+autoship_toggle.data("autoship-toggle").heading+"</span></div>");
                dpas.append("<div class=\"datepicker--foot\"><a class=\"part_ui_btn part_ui_btn_outline\" data-dialog-as-ad=\"?do=activateDeactivateAs\"><span>"+autoship_toggle.data("autoship-toggle").button+"</span></a></div>");

                $(document).on('click', "[data-autoship-toggle]", function () {
                    dp.show();
                });

                dpas.on("click","[data-dialog-as-ad]",function (e) {
                    e.preventDefault();

                    let selectedDate = $('input[data-as-datepicker-input]').val();
                    dp.hide();
                    let dialogUrl = $(this).attr('data-dialog-as-ad');

                    $.ajax({
                        url: dialogUrl,
                        method: 'post',
                        data: {
                            date: selectedDate
                        }
                    }).done(function (data) {
                        nl_lib_dialog.open(data.dialog, function() {
                            reInitDatePickerDialog();
                        });
                    });


                    setTimeout(function () {
                        $(this).removeClass("mod--active");
                    },1000);
                })
            });
        }

        function disablePaymentSelect(codePayment)
        {
            var checkFirstAvail = false;
            $('select#asOrderPayment option').each(function() {

                if($(this).attr('data-code') === codePayment) {
                    if($(this).is(':selected')) {
                        checkFirstAvail = true;
                    }
                    $(this).attr('disabled','disabled');
                }
            });

            if(checkFirstAvail) {
                $('select#asOrderPayment option').each(function() {

                    if($(this).attr('disabled') != 'disabled') {
                        $('select#asOrderPayment').val($(this).attr('value'));
                        return;
                    }
                });
            }
        }

        handleSelectedDelivery = function()
        {

            $('select#asOrderPayment option').each(function() {
                $(this).removeAttr('disabled');
            });

            var selectedShipping = $('#asOrderShipping').val();
            var selectedShippingCode = $('#asOrderShipping option:selected').attr('data-code').toLowerCase();

            if(selectedShippingCode === 'zas') {
                $('.elm_select_branches,.packetery-branch-list').slideDown();
            } else {
                $('.elm_select_branches,.packetery-branch-list').slideUp();
            }

            if(selectedShippingCode === 'bal') {
                $('.elm_select_branches_balikovna').slideDown();
            } else {
                $('.elm_select_branches_balikovna').slideUp();
            }

            if(selectedShippingCode === 'bal_post') {
                $('.elm_select_branches_balik_na_postu').slideDown();
            } else {
                $('.elm_select_branches_balik_na_postu').slideUp();
            }

            if(selectedShippingCode === 'balikobox') {
                $('.elm_select_branches_balikobox').slideDown();
            } else {
                $('.elm_select_branches_balikobox').slideUp();
            }

            if(selectedShippingCode === 'inpost') {
                $('.elm_select_branches_inpost').slideDown();
            } else {
                $('.elm_select_branches_inpost').slideUp();
            }

            if(selectedShippingCode === 'ppl_parcelshop') {
                $('.elm_select_branches_ppl_parcelshop').slideDown();
            } else {
                $('.elm_select_branches_ppl_parcelshop').slideUp();
            }

            // 1 = hotově
            // 3 = dobírka

            // osobni odber
            if (selectedShippingCode === 'oso') {
                disablePaymentSelect('dob');
            }

            // posta, GLS
            if (selectedShippingCode === 'gls' || selectedShippingCode === 'post') {
                disablePaymentSelect('cash');
            }

            if (selectedShippingCode === 'zas' || selectedShippingCode == 'bal' || selectedShippingCode == 'ppl_parcelshop') {
                disablePaymentSelect('cash');
            }

            //pl inpost
            if (selectedShippingCode === 'inpost') {
                disablePaymentSelect('cash');
                disablePaymentSelect('dob');
            }
        }
        //change select shipping
        $(document).on('change','#asOrderShipping',function() {
            handleSelectedDelivery();
        });


        var $notification = $('[data-notification]');
        if ($notification.length > 0) {
            $notification.each(function() {
                nl_lib_notification.open({
                    autohide: true,
                    state: $(this).data('state'),
                    title: $(this).data('title'),
                    text: $(this).data('text')
                });
            });
        }

        loadBalikovnaSelect = function() {
            function initTippy()
            {
                const template = document.querySelector('#balikovnaOpenHours');
                var initialText;
                if(template !== null && template) {
                    initialText = template.textContent;
                } else {
                    initialText = '...';
                }

                var tip = tippy('.openingHours', {
                    animation: 'shift-toward',
                    arrow: true,
                    html: '#balikovnaOpenHours',
                    onShow(instance) {
                        // `this` inside callbacks refers to the popper element
                        var content = this.querySelector('.tippy-content');

                        var customUrl = $(instance.reference).attr('data-url');

                        if (tip.loading || content.innerHTML !== initialText) return;

                        tip.loading = true;

                        $.ajax({
                            url: customUrl,
                            dataType: 'html'
                        }).done(function(data) {
                            content.innerHTML = data;
                            tip.loading = false
                        });

                    },
                    onHidden() {
                        const content = this.querySelector('.tippy-content');
                        content.innerHTML = initialText
                    },
                    // prevent tooltip from displaying over button
                    popperOptions: {
                        modifiers: {
                            preventOverflow: {
                                enabled: false
                            },
                            hide: {
                                enabled: false
                            }
                        }
                    }
                });
            }

            $(window).on('load',function() {
                initTippy();
            });

            function selectTemplate(data, container) {
                var branchId = data.id;
                var city = data.city;
                var cityPart = data.cityPart;
                var address = data.address;
                var kind = data.kind;
                if (kind === "posta") {
                    kind = "pošta"
                }

                if (!branchId) {
                    return "";
                } else {
                    return $(
                        //"<div class='city'>" + city + "</div>" +
                        "<div class='balikovna-address-content'><div class='cityPart'>" + cityPart + ",</div>" +
                        "<div class='address'>&nbsp;" + address + ",</div>" +
                        "<div class='kind'>&nbsp;" + kind + "</div></div>" +
                        "<div class='openingHours tooltipTrigger icon icon--clock' data-url='?do=balikovnaGetOpenings&bId="+ branchId +"'>" + "" + "</div>"
                    );
                }
            }

            var search;

            var balikovnaSelect = $("select#balikovna_pobocka");

            balikovnaSelect.select2({
                language: "cs",
                ajax: {
                    url: "?do=searchBalikovna",
                    data: function (params) {
                        search = params.term;
                        return {
                            q: params.term
                        };
                    },
                    processResults: function (data) {
                        setTimeout(function () {
                            initTippy();
                        }, 300);
                        return {
                            results: $.map(data.branches, function (item) {
                                return {
                                    id: item.id,
                                    text: item.name,
                                    city: item.city,
                                    cityPart: item.city_part,
                                    address: item.address,
                                    zip: item.zip,
                                    kind: item.kind
                                }
                            })
                        };
                    },
                    complete: function () {
                        if (search !== undefined) {
                            setTimeout(function () {
                                initTippy();
                            }, 100);
                        }
                    }
                },
                templateResult: selectTemplate,
                templateSelection: selectTemplate
            });

            balikovnaSelect.on('select2:select',function() {
                setTimeout(function () {
                    initTippy();
                }, 100);
            });
        };

        /*$(document).on('change','input[name="reasonQuitAs"]', function() {

            if($(this).is('[data-as-quit-other-reason-button]') && $(this).prop('checked') === true) {
                $('div[data-as-quit-other-reason]').show();
            } else {
                $('div[data-as-quit-other-reason]').hide();
            }

        });*/

    }
})(jQuery);
