function libPlayerAudio(elm) {
    let media = elm.querySelector("[data-ref-media]"),
        progress = elm.querySelector("[data-ref-progress]"),
        progressBar = elm.querySelector("[data-ref-progress-bar]"),
        toggle = elm.querySelector("[data-toggle-play]"),
        current = elm.querySelector("[data-ref-current]"),
        total = elm.querySelector("[data-ref-duration]");

    toggle.addEventListener("click", (e) => {
        e.preventDefault();

        if (media.paused) {
            media.play();
        } else {
            media.pause();
        }
    })

    media.addEventListener("play", () => {
        elm.classList.add("mod--active");
        total.textContent = moment.utc(media.duration*1000).format('mm:ss');
    })

    media.addEventListener("pause", () => {
        elm.classList.remove("mod--active");
    })

    media.addEventListener("timeupdate", () => {
        const percent = (media.currentTime / media.duration) * 100;
        progressBar.style.width = `${percent}%`;
        current.textContent = moment.utc(media.currentTime*1000).format('mm:ss');
    })

    progress.addEventListener("click", (e) => {
        media.currentTime = (e.offsetX / progress.clientWidth) * media.duration;
    })
}