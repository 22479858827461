(function ($) {
    var $fn = $(".part_categories");

    $(document).on("click",".part_categories [data-more]",function (e) {

        let $el = $(this);
        e.preventDefault();

        $el.stop().toggleClass("mod--active");

        $el.closest('.part_categories').find(".elm_body__mobile").find("[aria-hidden]").stop().slideToggle();
    });

    $(document).on('input', '.part_categories [data-tag-search]', function () {
        var elm = $(this);
        naja.makeRequest('GET', elm.data('tag-search'), {search: elm.val()});
    });

    $(document).on('change', '.part_categories [data-tag-sort]', function () {
        var elm = $(this);

        if (elm.val()) {
            naja.makeRequest('GET', elm.val());
        }
    });
})(jQuery);