var initInputValidation;
(function($) {
    if (!$html.hasClass("ie")) {
        $.fn.validation = function (opts) {

            $(this).removeClass("mod--invalid mod--valid");
            if ($(this).find("input, textarea").val() !== "" || (opts && typeof opts.initial !== 'undefined' && opts.initial === true)) {

                if($(this).children().hasClass('intl-tel-input')) {
                    //tel input
                    if ($(this).find('input[type="tel"]').intlTelInput("isValidNumber")) {
                        $(this).addClass("mod--valid");
                    } else {
                        $(this).addClass("mod--invalid");
                    }
                } else if($(this).is('[data-birthdate]')) {

                    let value = $(this).find("input").val();
                    let date = moment(value,'DD.MM.YYYY');
                    let todayMinus18 = moment().subtract(18, 'years');
                    let diffYears = moment().diff(date,'years');

                    if(!value.length || !date || date > todayMinus18 || diffYears > 120) {
                        $(this).addClass("mod--invalid");
                    } else {
                        $(this).addClass("mod--valid");
                    }
                } else if($(this).is('[data-password-second]')) {

                    var validInput = $(this).find("input").is(":valid");
                    var firstVal =  $('[data-password-first]').find("input").val();
                    var secondVal =  $('[data-password-second]').find("input").val();

                    if(validInput && firstVal === secondVal) {
                        $(this).addClass("mod--valid");
                    } else {
                        $(this).addClass("mod--invalid");
                    }

                } else {
                    if ($(this).find("input, textarea").is(":valid")) {
                        $(this).addClass("mod--valid");
                    } else {
                        $(this).addClass("mod--invalid");
                    }
                }
            }

        };

        $(document).on("change", ".part_ui_input", function () {
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validation();
            }
        });

        $(document).on("change", ".part_ui_input[data-form-file] input", function(){
            let elm = $(this);

            elm.closest(".part_ui_input").find("span").text($(this).val().replace(/.*(\/|\\)/, ''));
        });

        $(".part_ui_input:not([data-init-validation])").each(function () {
            if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid") && !$(this)[0].outerHTML.match(/(hidden)/)) {
                $(this).validation();
            }
        });


        initInputValidation = function()
        {
            $(".part_ui_input[data-init-validation]").each(function () {
                if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid")) {
                    $(this).validation({
                        initial: true
                    });
                }
            });
        };
        initInputValidation();
    }
})(jQuery);