(function ($) {
    var $fn = $(".comp_forms");

    if ($fn.length) {

        $fn.on("click", ".elm_item", function (e) {
            window.open($(this).find(".elm_view").attr("href"));
        });

        $fn.on("click",".elm_item .part_ui_btn",function (e) {

            if($(this).is('[data-app-download]') && ((typeof cordova !== 'undefined' && typeof cordovaDeviceReady !== 'undefined' && cordovaDeviceReady === true) || window.Capacitor)) {
                linkDownloadFileCordova($(this));
                e.preventDefault();
            } else if($(this).is('[data-app-browser-open]') && ((typeof cordova !== 'undefined' && typeof cordovaDeviceReady !== 'undefined' && cordovaDeviceReady === true) || window.Capacitor)) {
                linkOpenInWindowCordova($(this));
            }

            e.stopPropagation();
        });

        $.ajax({
            method : 'post',
            dataType: 'json',
            data: {
                do: 'compressFiles'
            }
        }).done(function(data) {

        });

    }

})(jQuery);
