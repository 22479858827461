function fn_lib_dialog() {
    var $body = $("body");
    var $dialog = $("[data-dialog]");
    var $originalUrl = '';

    function fn_lib_dialog_show(data,callback) {
        $(document).find('.lib--dialog [class^="part_dialog"]').remove();
        if (!$(document).find(".lib--dialog").length) {
            $body.append('<div class="lib--dialog"></div>');
        }
        $(document).find(".lib--dialog").append(data).fadeTo(300,1);
        $(document).find(".lib--dialog .part_dialog").focus();

        if (doc.find(".lib--dialog > .part_dialog").outerHeight(true) > win.height()) {
            let offset = window.innerWidth - document.body.clientWidth;
            body.css("padding-right", offset).addClass("mod--no-scroll");
            $("#layout_header").css("right", offset);
        }

        if (callback) {
            callback();
        }

        $(document).find(".lib--dialog").on("click", function(e) {
            var preventOverlayClose = $('div.lib--dialog').children().is('[data-dialog-prevent-overlay-close]');
            if ($(e.target).hasClass("lib--dialog") && !preventOverlayClose) {
                fn_lib_dialog_hide();
            }
        });
    }

    function fn_lib_dialog_hide() {

        $dialog.removeClass("mod--active");
        $(document).find(".lib--dialog").fadeOut(300);
        if ($originalUrl) {
            window.history.replaceState(null, '', $originalUrl);
            $originalUrl = '';
        }

        setTimeout(function () {
            $body.removeClass("mod--no-scroll").css("padding-right","");
            $("#layout_header").css("right","");

            $(document).find('.lib--dialog [data-picker-destroy]').each(function() {
                let datepickerInstance = $(this).data('datepicker');
                if(datepickerInstance) {
                    datepickerInstance.destroy();
                }
            });

            $(document).find(".lib--dialog").remove();
        }, 500);
    }

    function fn_lib_dialog_init(callback) {
        $(document).on('click', '[data-dialog]', function (e) {
            e.preventDefault();
            var $el = $(this);
            // $el.addClass("mod--active");

            $.ajax({
                url: $el.attr('data-dialog')
            }).done(function (data) {
                if (data.url) {
                    $originalUrl = window.location.href;
                    window.history.replaceState(null, '', data.url);
                }
                fn_lib_dialog_show(data.dialog, callback);
            });
        });

        $(document).on("click", "[data-dialog-close]", function (e) {
            if(!$(this).filter('[data-no-prevent]').length) {
                e.preventDefault();
            }
            if($(this).filter('[data-dialog-sticky]').length) {
                return;
            }
            fn_lib_dialog_hide();
        });
    }

    return {
        open: fn_lib_dialog_show,
        close: fn_lib_dialog_hide,
        init: fn_lib_dialog_init
    };
}
var nl_lib_dialog = fn_lib_dialog();