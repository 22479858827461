(function($){

    $(document).on("click", ".comp_faq [data-tag-search]", function(){
       html.animate({
           scrollTop: $(this).offset().top - 24
       }, 500);
    });

    $(document).on("click", ".comp_faq [data-toggle-item]", function(){
        var elm = $(this);
        elm.closest(".elm_item").toggleClass("mod--active").find(".wrp_item__body").stop(true).slideToggle(300);
    });

})(jQuery);