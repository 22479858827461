(function ($) {
    var $fn = $(".comp_registration_email");

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    if ($fn.length) {
        doc.on('click', '[data-copy-link]', function () {
            var input = $('[name="mylink"]')[0];

            navigator.clipboard.writeText(input.value).catch(() => {
                if (typeof window.Capacitor !== 'undefined') {
                    window.writeToClipboardCapacitor(input.value);
                }
            })

            let successElem = $('[data-copy-link-success]');

            $('#snippet--regLinkCopy').html('');

            if(successElem.length) {
                successElem.slideDown('fast');
                // setTimeout(function() {
                //     successElem.slideUp('fast');
                // },3000);
            }
        });

        doc.on('change','[data-link-contract-type] input',function() {
            let type = $(this).closest('[data-link-contract-type]').attr('data-link-contract-type').toString();

            //input
            let inputLink = $('input[name="mylink"]');
            let currVal = inputLink.val().toString();

            currVal = replaceAll(currVal,'type=OS','type=' + type);
            currVal = replaceAll(currVal,'type=KS','type=' + type);


            //messenger link
            inputLink.val(currVal);

            let messLink = $('a[data-toggle-button="messenger"]');
            if(messLink.length) {
                let currValLinkGlobal = messLink.attr('data-copy-link-global').toString();
                let currValLinkHref = messLink.attr('href').toString();

                currValLinkGlobal = replaceAll(currValLinkGlobal,'type%3DOS','type%3D' + type);
                currValLinkGlobal = replaceAll(currValLinkGlobal,'type%3DKS','type%3D' + type);
                currValLinkHref = replaceAll(currValLinkHref,'type%3DOS','type%3D' + type);
                currValLinkHref = replaceAll(currValLinkHref,'type%3DKS','type%3D' + type);

                messLink.attr('data-copy-link-global', currValLinkGlobal);
                messLink.attr('href',currValLinkHref);
            }
        });

        $(document).on('change','[data-link-change-country]',function() {
            let country = $(this).val();
            naja.makeRequest('POST', $(this).data('link-change-country'), {country: country});
        });

        $(document).on('click','[data-regenerate-reg-link]',function() {
            navigator.clipboard.writeText('')

            let currentCountry = $('select[data-link-change-country]').val();
            let currentType = $('input[name="contractType"]:checked').val();
            naja.makeRequest('POST', location.href, {do: 'clearRegHash', country: currentCountry, regLinkType: currentType},{history: false});
        });

    }
})(jQuery);
