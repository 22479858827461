(function ($) {
    var $fn = $(".comp_registration");

    if ($fn.length) {
        var $notification = $('[data-notification]');
        if ($notification.length > 0) {
            $notification.each(function() {
                nl_lib_notification.open({
                    state: $(this).data('state'),
                    title: $(this).data('title'),
                    text: $(this).data('text')
                });
            });
        }
        
        function checkIfAllApproved()
        {
            var $approved_all;
            var $approved_nav = $fn.find("[data-approved-nav]");
            $fn.find(".part_registration > .wrp_part:not([data-no-check])").each(function () {
                if (!$(this).hasClass("mod--approved")) {
                    $approved_all = false;
                    return false;
                } else {
                    $approved_all = true;
                }
            });

            if ($approved_all === true) {
                $approved_nav.removeAttr("disabled");
            } else {
                $approved_nav.attr("disabled", "");
            }
        }

        $fn.on("click", ".part_registration [data-toggle-approved]", function () {
            var $elm = $(this),
                    $edit = $('.part_registration').find(".wrp_part:not([data-no-check])").find("[data-toggle-edit]"),
                    $parts = $('.part_registration').find(".wrp_part:not([data-no-check])");

            $elm.css("width", "");

            if ($elm.hasClass("mod--approved")) {
                $elm.removeClass("mod--approved");
                $parts.removeClass("mod--approved");
                $elm.find("i:not(.icon)").show();
                if ($edit.length) {
                    $edit.show();
                }
            } else {
                if ($edit.filter(".mod--save").length) {

                    if(!$('.part_registration[data-empty-user-data]').length) {
                        $edit.filter(".mod--save").trigger("click");
                    }

                    var hasInvalidParts = false;
                    $edit.each(function () {
                        if ($(this).hasClass("mod--invalid")) {
                            hasInvalidParts = true;
                        }
                    });

                    if (!hasInvalidParts) {
                        if($('.part_registration[data-empty-user-data]').length) {

                            $elm.addClass("mod--approved");
                            $parts.addClass("mod--approved");
                        } else {
                            $edit.not('[data-toggle-edit-stay]').hide();
                            $elm.addClass("mod--approved");
                            $parts.addClass("mod--approved");
                        }

                    }
                } else {
                    if ($edit.length) {
                        $edit.not('[data-toggle-edit-stay]').hide();
                    }
                    $elm.css("width", $elm.outerWidth());

                    $parts.addClass("mod--approved");

                    $elm.find("i:not(.icon)").fadeOut(100);

                    setTimeout(function () {
                        $elm.css("width", "");
                        $elm.addClass("mod--approved");
                    }, 300);
                }
            }
            checkIfAllApproved();
        });

        checkIfAllApproved();

        $fn.on('click', '[data-agree-block]', function () {

            var _this = this;
            setTimeout(function() {
                var agreeBlock = $(_this).attr('data-agree-block');
                var isApproved;
                if ($(_this).hasClass('mod--approved')) {
                    isApproved = 1;
                } else {
                    isApproved = 0;
                }

                $.ajax({
                    method: 'post',
                    dataType: 'json',
                    data: {do: 'setAgreeBlock', block: agreeBlock, approved: isApproved}
                }).done(function (data) {

                });
            }, 500);

        });

        $fn.on('mouseleave', '.part_item_basket_product.mod--hovered', function () {
            $(this).removeClass('mod--hovered');
        });

        //autoship day
        function checkAutoshopSelected() {
            if ($('[data-autoship-date-section]').length) {
                var displayASDates = false;
                if ($('select[name="autoship"]').val() == '1') {
                    displayASDates = true;
                }

                if (displayASDates) {
                    $('[data-autoship-date-section]').show();
                } else {
                    $('[data-autoship-date-section]').hide();
                }
            }
        }

        $fn.on('change', 'select[name="autoship"]', function () {
            checkAutoshopSelected();
        });
        checkAutoshopSelected();

        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/numeral.js/2.0.6/numeral.min.js").done(function () {

            numeral.localeData().delimiters.thousands = ' ';

            if ($('html').data('currency') == 'eur') {
                numeral.defaultFormat('0,0.00');
            } else if ($('html').data('currency') == 'czk') {
                numeral.defaultFormat('0,0');
            } else if($('html').data('currency') == 'pln') {
                numeral.defaultFormat('0,0.00');
            }


            $(document).on('click', '.part_item_autoship_add [data-number]', function () {

                if(typeof otherProductsChangePlus !== 'undefined') {
                    if($(this).attr('data-number') === 'plus') {
                        otherProductsChangePlus = true;
                    } else {
                        otherProductsChangePlus = false;
                    }
                }

                var parent = $(this).closest('.part_item_autoship_add');
                var count = parent.find('input[data-secondary-count]').val();
                var default_unit_price = parent.find('[data-unit-price]').data('unit-price');
                var unit_price = default_unit_price;
                var price_elm = parent.find('span[data-price]');

                if (count >= 5 && parent.find('[data-unit-price3]').length) {
                    var unit_price_discounted = parent.find('[data-unit-price3]').data('unit-price3');
                    var spare_pieces = count % 5;
                    var total_price = (count - spare_pieces) * unit_price_discounted + spare_pieces * default_unit_price;

                    unit_price = total_price / count;
                }

                if (count >= 10 && parent.find('[data-unit-price2]').length) {
                    var unit_price_discounted = parent.find("[data-unit-price2]").data("unit-price2");
                    var spare_pieces = count % 10;
                    var total_price = (count - spare_pieces) * unit_price_discounted + spare_pieces * default_unit_price;

                    unit_price = total_price / count;
                }

                price_elm.text(numeral(count * unit_price).format());

            });
        });

        $(document).on('submit','form.form_registration_do',function() {
            $(this).find('button[type="submit"]').attr('disabled','disabled');
        });


        function checkPayVatDicInput()
        {
            if($('html').attr('data-country') !== 'cz') {
                return;
            }

            if(!$('select[name="payVat"]').length) {
                return;
            }

            let dicInput = $('input[name="dic"]');
            let selected = parseInt($('select[name="payVat"]').val());

            if(selected === 1) {
                dicInput.attr('required','required');
                dicInput.closest('.part_ui_input').removeAttr('data-no-validation');
                dicInput.closest('.part_ui_input').attr('data-init-validation','');
                try {
                    dicInput.closest('.part_ui_input').validation({
                        initial: true
                    });
                } catch (err) {

                }
            } else {
                dicInput.removeAttr('required');
                dicInput.closest('.part_ui_input').attr('data-no-validation','');
                dicInput.closest('.part_ui_input').removeAttr('data-init-validation');
                try {
                    dicInput.closest('.part_ui_input').validation();
                } catch (err) {

                }
            }

        }
        checkPayVatDicInput();

        if($('html').attr('data-country') === 'cz' && $('select[name="payVat"]').length) {
            $(document).on('change','select[name="payVat"]',function() {
                checkPayVatDicInput();
            });
        }

    }
})(jQuery);
