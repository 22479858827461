(function($){
    const fn = $('[data-controller="details"]');

    if (fn.length) {
        fn.each(function() {
            let elm = $(this);
            let body = elm.find('[data-target="body"]')

            elm.on('change', '[data-action="toggle"]', function (e) {
                e.preventDefault();
                if(e.currentTarget.checked) {
                    body.stop().slideDown(300)
                } else {
                    body.stop().slideUp(300)
                }

                let target = $(e.currentTarget)
                if (target.data('url')) {
                    naja.makeRequest('GET', target.data('url'), {name: target.attr('name'), value: e.currentTarget.checked});
                }
            })
        })
    }
})(jQuery);
