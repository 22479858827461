(function($){
    var $fn = $(".comp_events");

    var offsetYears = parseInt($('.comp_events').attr('data-offset-years'));
    var year_window_width = $fn.find(".elm_columns_inner").width();

    var currentYear = null;
    var checkShowHideCategories = false;

    if($fn.length) {

        currentYear = parseInt($fn.find("[data-current-year]").html());

        console.log('current year');
        console.log(currentYear);

        function calcHeight() {
            $fn.find(".elm_event_row").each(function(){
                let elm = $(this);
                let index = elm.index();
                let height = elm.outerHeight();

                $fn.find(".elm_inner_categories").children().eq(index+1).css("height",height);
                $fn.find(".elm_columns_inner").children().eq(index).css("height",height);
            });

            year_window_width = $fn.find(".elm_columns_inner").width();
        }

        calcHeight();

        function showHideEmptyCategories()
        {
            if(!checkShowHideCategories) {
                return;
            }

            let replaceAttr = 'data-dialog';
            let shownCategories = [];
            let calendarDialogLink = $('a[data-dialog-calendar]').first();
            if (calendarDialogLink.length > 0) {
                calendarDialogLink.attr(replaceAttr, calendarDialogLink.attr(replaceAttr).replaceAll(/&cats=[0-9,]+/ig, ''));
            }

            $('[data-event-category-head]').each(function() {

                if($(this).is('[data-event-category-fixed]')) {
                    return;
                }

                let categoryId = $(this).attr('data-event-category-head');
                let showInYears = JSON.parse($(this).attr('data-event-category-show-in'));

                let row = $('[data-event-category-row="' + categoryId  + '"]');
                let items = $('[data-event-category-items="' + categoryId  + '"]');
                let head = $(this);

                if(showInYears.indexOf(currentYear) !== -1) {
                    row.fadeIn();
                    items.fadeIn();
                    head.fadeIn();
                    shownCategories.push(categoryId);
                } else {
                    row.fadeOut();
                    items.fadeOut();
                    head.fadeOut();
                }

            })

            if (calendarDialogLink.length > 0) {
                calendarDialogLink.attr(replaceAttr, calendarDialogLink.attr(replaceAttr) + '&cats=' + shownCategories.join(','));
            }
        }

        win.on("resize", calcHeight);

        body.append(`<div class="part_tooltip"><span></span></div>`);

        let tooltip = $(".part_tooltip");

        window.onmousemove = function (e) {
            let x = (e.clientX + 20) + 'px',
                y = (e.clientY + 20) + 'px';
            tooltip.css("top",y).css("left",x);
        };

        $fn.find("[data-tooltip]").hover(function() {
            let elm = $(this);
            tooltip.css("background-color",elm.css("background-color"));
            tooltip.find("span").text(elm.data("tooltip"));
            tooltip.show();
        }, function() {
            tooltip.hide();
        });

        $fn.on("click", "[data-event-year]", function() {
            let elm = $(this);
            let year_width = $fn.find(".elm_columns_inner").width();
            let position = $fn.find(".elm_rows_scroll").scrollLeft();
            let overlap = position % $fn.find(".elm_columns_inner .col").outerWidth();

            if (elm.data("event-year") === "next") {
                $fn.find(".elm_rows_scroll").animate({scrollLeft: '+=' + (year_width - overlap)}, 300);
            } else {
                $fn.find(".elm_rows_scroll").animate({scrollLeft: '-=' + year_width}, 300);
            }
        });

        cssLoaded(function(){
            $fn.find(".elm_rows_scroll").scrollLeft($fn.find("[data-scroll-start]").position().left);

            if(offsetYears > 0) {
                let year_width = $fn.find(".elm_columns_inner").width();
                let position = $fn.find(".elm_rows_scroll").scrollLeft();
                let overlap = position % $fn.find(".elm_columns_inner .col").outerWidth();

                if(isNaN(overlap)) {
                    overlap = 0
                }

                $fn.find(".elm_rows_scroll").animate({scrollLeft: '+=' + ((year_width * offsetYears) - overlap)}, 0,'swing', function() {
                    setTimeout(function() {
                        checkShowHideCategories = true;
                        showHideEmptyCategories();
                    },100);
                });
            }
        });

        $fn.find(".elm_rows_scroll").on("scroll", function() {
            let elm = $(this);

            //priprava na update pozice názvu položky kalendáře

            /*elm.find("[data-event-item]").each(function() {
                let itemOuterWidth = $(this)

            });*/

            let lastYear = null;

            elm.find("[data-year-start]").each(function(i){

                if ($(this).position().left <= 15) {
                    var year = parseInt($(this).data("year-start"));
                    lastYear = year;

                    $fn.find("[data-current-year]").text(year);
                }
            });

            if(lastYear) {
                if(lastYear !== currentYear) {
                    currentYear = lastYear;
                    showHideEmptyCategories();
                }
            }

        });

        doc.on('click', '[data-save-event]', function() {
            $('#frm-eventForm').find('[type="submit"]').click();
        });

        doc.on('change', '[data-calendar-image]', function() {
            if (this.files && this.files[0]) {
                $('[data-calendar-cropper-wrapper]').toggle();
                $('[data-calendar-form]').toggle();
                $('.part_dialog_event').css('max-width', '50em');

                var reader = new FileReader();
                reader.onload = function (e) {
                    if ($('#cropper').data('cropper') !== undefined) {
                        $('#cropper').data('cropper').replace(e.target.result);
                    } else {
                        var img = $(`<img id="cropper" src="${e.target.result}">`);

                        $('[data-calendar-cropper-wrapper]').prepend(img);

                        $('#cropper').cropper({
                            aspectRatio: 2.25,
                            viewMode: 1,
                            zoomable: false,
                            guides: false,
                            minCropBoxWidth: 450,
                            minCropBoxHeight: 200,
                        });
                    }
                };

                reader.readAsDataURL(this.files[0]);
            }
        });

        doc.on('click', '[data-calendar-use-image]', function() {
            $('[data-calendar-cropper-wrapper]').toggle();
            $('[data-calendar-form]').toggle();
            $('.part_dialog_event').css('max-width', '27.75em');

            var binary = $('#cropper').cropper('getCroppedCanvas').toDataURL('image/jpeg', 0.9);

            $('[data-calendar-image-preview]').attr('src', binary);
            $('[name="image_binary"]').val(binary);
        });

        doc.on('change','[data-calendar-category]',function(e) {

            var selected = [];

            $('[data-calendar-category]').each(function() {
                if($(this).prop('checked') === true) {
                    selected.push($(this).val());
                }
            });

            var toAdd = '';

            if(selected.length) {
                toAdd = '?cats=' + encodeURIComponent(selected.join(','));
            }

            var originalLink = $('[data-calendar-link]').attr('data-original').replaceAll(/\?cats=[0-9%A-Z]+/ig, '');

            $('[data-calendar-link]').val(originalLink + toAdd);

        });

        doc.on('click', '[data-copy-link]', function (e) {
            var input = $('[data-calendar-link]')[0];

            navigator.clipboard.writeText(input.value).catch(() => {
                if (typeof window.Capacitor !== 'undefined') {
                    window.writeToClipboardCapacitor(input.value);
                }
            })

            /*document.selection.empty();*/

            $('[data-success-copy]').slideDown('fast');
        });

    }
})(jQuery);
