var partUiWsw = null;

(function($) {
    partUiWsw = {
        init: function() {
            var $fn = $(".part_ui_wsw");
            $fn.find("table").each(function() {
                if(!$(this).parent().is('.elm_table')) {
                    $(this).wrap("<div class='elm_table'></div>");
                }
            });
            $fn.find('iframe').each(function() {
                if((this.src.match(/youtube\.com/i) || this.src.match(/vimeo\.com/i)) && !$(this).parent().is('.elm_video')) {
                    $(this).wrap("<div class='elm_video'></div>")
                }
            });
        }
    };

    partUiWsw.init();

})(jQuery);
